import React from 'react';
import { Image } from 'semantic-ui-react';
import { Button as DSButton } from '@classapp-tech/edna';
import { XmarkSolid } from '@classapp-tech/edna-icons';
import * as utils from '../../utils';
import Modal from './Modal';

const styles = {
  containerFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: 488,
    height: 297,
  },
  title: {
    fontSize: 22,
    marginBottom: 20,
    fontWeight: 700,
    color: '#000000',
  },
  content: {
    fontSize: 16,
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  actionButtonContainer: {
    alignSelf: 'center',
    marginTop: 24,
    marginBottom: 24
  }
};
/**
 * @typedef {Object} FeatureAnnouncementProps
 * @property {string} title
 * @property {string} content
 * @property {Function} onClickActionButton
 * @property {Function} onClose
 * @property {string} submitButtonText
 * @property {string} image
 */
/**
 * @type {React.FC<FeatureAnnouncementProps>}
 */
export default class FeatureAnnouncement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  componentDidMount() {
    const { onShow } = this.props;
    if (onShow) onShow();
  }

  render() {
    const {
      title, content, onClickActionButton, onClose, submitButtonText, image
    } = this.props;
    const { showModal } = this.state;
    return (
      showModal && (
      <Modal
        onClose={onClose}
        className="featureAnnouncementModal"
      >
        <Modal.Content style={styles.containerFlex}>
          <XmarkSolid
            data-testid="close-ann-modal"
            style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
            width={16}
            height={16}
            onClick={() => {
              this.setState({ showModal: false });
              onClose();
            }}
          />
          <div style={styles.containerFlex}>
            <Image alt="" src={utils.asset(image)} style={styles.image} />
            <div>
              <p style={styles.title}>{title}</p>
              <p style={styles.content}>{content}</p>
            </div>
          </div>
          <div style={styles.actionButtonContainer}>
            {!!onClickActionButton && !!submitButtonText && (
            <DSButton
              data-testid="learn-more-ann-modal"
              variation="primary"
              onClick={() => {
                this.setState({ showModal: false });
                onClickActionButton();
              }}
            >
              {submitButtonText}
            </DSButton>
            )}
          </div>
        </Modal.Content>
      </Modal>
      )
    );
  }
}
