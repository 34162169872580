import React from 'react';
import Button from './Button';
import ColoredCheckbox from '../ColoredCheckbox';
import { Icon } from 'semantic-ui-react';
import { union } from 'lodash';

import Loading from './Loading';

import { __ } from '../../i18n';

export default class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
      searchQuery: ''
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onClear = () => {
    const { onClear } = this.props;

    if (onClear) onClear();
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && this.state.showOptions
        && !this.wrapperRef.contains(event.target)) this.setState({ showOptions: false });
  }

  // eslint-disable-next-line no-undef
  startTimeout = async () => {
    const { searchQuery } = this.state;
    clearTimeout(this.lastRequestId);
    let id;
    // eslint-disable-next-line no-multi-assign
    this.lastRequestId = id = setTimeout(() => this.props.onSearch(searchQuery), 600);
  }

  renderSearchheader = () => (
    <div className="search-header">
      <i className="icon search icon-before" />
      <input className="search-input" onChange={(e) => { this.setState({ searchQuery: e.target.value }, () => this.startTimeout()); }} />
    </div>
  )

  renderOptions = (options, optionsHeader, onSelect) => {
    if (options[0].length < 1) {
      return (<div style={{ marginTop: '5px', color: '#b4b4b4', fontSize: '1.1rem' }}>
        <span>{__('No results found')}</span>
      </div>);
    }

    return (
      options.map((option, i) =>
        <div>
          {optionsHeader &&
          <div style={{ color: 'rgba(153, 153, 153, 1)', padding: '5px', marginTop: i !== 0 ? '5px' : '0px' }}>
            {optionsHeader[i]}
          </div>
          }
          {option.map(
            (o, index) => (
              <div onClick={() => { onSelect(o.value || o.key, i, o); }} style={{ marginTop: '5px' }}>
                <ColoredCheckbox checked={o.selected} label={o.text} />
              </div>
            ))}
        </div>
      )
    );
  }

  render() {
    const {
      name, options, onSelect, triggerStyle, className, onSubmit,
      onOpen, disabled, menuStyle, optionsHeader = [], search
    } = this.props;
    const { showOptions } = this.state;
    const mergeDedupe = arr => [...new Set([].concat(...arr))];
    const selectedCount = mergeDedupe(options).reduce((acc, option) => acc + !!option.selected, 0);
    return (
      <div
        className={`multiselect${className ? ` ${className}` : ''}`}
        ref={(el) => { this.wrapperRef = el; }}
      >
        <Button
          text={(
            <div>
              {name}

              {selectedCount > 0 && (
                <div className="filters-number">
                  <span>{selectedCount}</span>
                </div>
              )}
              <Icon name="angle down" style={{ marginLeft: '.5rem' }} />
            </div>
          )}
          style={triggerStyle}
          onClick={() => {
            this.setState({ showOptions: !showOptions });
            if (!showOptions && onOpen) onOpen();
          }}
          disabled={disabled}
          transparent
          round
        />


        {showOptions && (
          <div className="options-menu" style={menuStyle}>
            {search && this.renderSearchheader()}
            <div className="menu-item-container" style={{ paddingBottom: '10px', paddingLeft: '10px' }}>
              {this.props.loading ? <Loading style={{ marginTop: '0' }} width={30} height={30} />
                : this.renderOptions(options, optionsHeader, onSelect)
              }
            </div>
            <div className="bottom-actions">
              <Button
                text={__('Limpar')}
                onClick={this.onClear}
                transparent
                round
              />

              {onSubmit && (
                <Button
                  text={__('Apply')}
                  onClick={() => this.setState({ showOptions: false }, onSubmit)}
                  round
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
