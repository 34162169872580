import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';

import Modal from './Modal';

import { __ } from '../../i18n';

@inject('store')
@observer
export default class SaveChangesModal extends Component {
  render() {
    return (
      <Modal
        header={__('Save changes')}
        content={
          <div>
            <div>
              <span>{__('Are you sure you want to save changes?')}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '30px' }}>
              <Button
                basic
                style={{ height: '43px', borderRadius: '20px' }}
                content={__('Cancel')}
                onClick={() => this.props.store.appends.pop()}
              />
              <Button
                style={{ height: '43px', borderRadius: '20px', backgroundColor: '#0069FF', color: '#FFF' }}
                content={__('Save changes')}
                onClick={() => { this.props.onClick(); this.props.store.appends.pop(); }}
              />
            </div>
          </div>}
      />
    );
  }
}
