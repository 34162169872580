import React from 'react';
import { Icon } from 'semantic-ui-react';

import moment from 'moment';
import MessageApprovalIcon from './MessageApprovalIcon';
import '../../assets/css/ui/ReviewStatusTooltip.module.scss';
import { __ } from '../../i18n';

/**
 * @typedef {{
 *  status: 'approved' | 'inReview' | 'sendLater',
 *  entityFullname: string,
 *  date: string | Date | number,
 * }} Props
 * @extends {React.Component<Props>}
 */

export default class ReviewStatusTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipVisible: true
    };
  }

  renderSendLaterLabel = () => {
    const { status, date } = this.props;

    return (
      <div id="ReviewStatusTooltip" className="sendLater">
        <div id="ReviewStatusTooltip">
          <Icon
            name="clock"
            className={`tooltip-icon ${status}`}
            fitted
          />
          <div
            className={`text ${status}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: __('Sending on: %s', date)
            }}
          />
        </div>
        <div onClick={()=> this.setState({ tooltipVisible: false })}>
          <Icon
            name="times"
            className={`tooltip-icon ${status} close-icon ${status}`}
            fitted
          />
        </div>
      </div>
    );
  }

  
  render() {
    const {
      status, entityFullname, date,
    } = this.props;
    const { tooltipVisible } = this.state;
    const isApproved = status === 'approved';
    const isSendLater = status === 'sendLater';
    return (

      tooltipVisible && (isSendLater ? this.renderSendLaterLabel()
        : (
          <div id="ReviewStatusTooltip" className={`${status}`}>
            {
          isApproved
            ? (
              <Icon
                data-testId="ReviewStatusTooltip"
                name="thumbs up"
                className={`tooltip-icon ${status}`}
                fitted
              />
            )
            : (
              <MessageApprovalIcon
                height="40"
                width="30"
                style={{ marginLeft: '20px', marginRight: '20px' }}
                fillColor="#616161"
              />
            )
        }

            <div
              className={`text ${status}`}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: isApproved ? __('Message approved by %s on %s', entityFullname, date) : __('Message sent for approval on %s', date)
              }}
            />
          </div>
        ))

    );
  }
}
