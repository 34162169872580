import React from 'react';
import { Icon, Label } from 'semantic-ui-react';

import '../../assets/css/ui/Recipients.module.scss';

import BetaModal from './Modal';
import Avatar from '../Avatar';
import { __ } from '../../i18n';


export default class RecipientsModal extends React.Component {
    getContentSize = () => {
      const innerWidth = window && window.innerWidth;
      if (innerWidth > 435) return 12;
      if (innerWidth > 400) return 9;
      if (innerWidth > 380) return 8;
      if (innerWidth > 370) return 7;
      return 5;
    }

    getContentSizeGroup = () => {
      const innerWidth = window && window.innerWidth;
      if (innerWidth > 435) return 20;
      if (innerWidth > 400) return 17;
      if (innerWidth > 380) return 16;
      if (innerWidth > 370) return 15;
      return 13;
    }

    render() {
      const {
        selectedEntities, onClose, onRemove, groups
      } = this.props;
      return (
        <BetaModal
          toast
          id="RecipientsModal"
          onClose={() => onClose()}
          closeOnRootNodeClick
          invertCloseButton
          scrolling
          styleScrollingContent={{ height: '80%', marginLeft: '-6px' }}
          header={__('Recipients')}
          content={
            <div style={{ height: 'calc(10vh)', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
              {groups ? selectedEntities.map(g =>
                <Label
                  className={'image round align-icon'}
                  size="tiny"
                  style={{ border: '1px solid #D6D6D6', display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 400, marginLeft: '6px', marginBottom: '6px', height: '48px' }}
                  removeIcon="delete"
                >
                  {g.name.length > this.getContentSizeGroup() ? (g.name.substring(0, this.getContentSizeGroup()) + '...') : g.name}
                  <Icon name="delete" onClick={() => onRemove(g)} />
                </Label>
              )
                :
                selectedEntities.map(e =>
                  <Label
                    className={'image round align-icon'}
                    size="tiny"
                    style={{ border: '1px solid #D6D6D6', display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 400, marginLeft: '6px', marginBottom: '6px', height: '48px' }}
                    removeIcon="delete"
                  >
                    <Avatar avatar spaced="right" src={e.picture && e.picture.uri} alt={e.fullname} />
                    {e.fullname.length > this.getContentSize() ? (e.fullname.substring(0, this.getContentSize()) + '...') : e.fullname}
                    <Icon name="delete" onClick={() => onRemove(e)} />
                  </Label>
                )
              }
            </div>
          }
        />
      );
    }
}
