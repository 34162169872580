/* eslint-disable indent */
import React from 'react';
import { toJS } from 'mobx';
import { Loader, Popup } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { uniqBy, remove, cloneDeep } from 'lodash';
import { __ } from '../../i18n';
import * as utils from '../../utils';
import BetaModal from './../ui/Modal';
import Input from './../ui/Input';
import Tag from './../ui/Tag';
import ColoredCheckbox from './../ColoredCheckbox';
import Loading from '../../components/ui/Loading';
import NestedCheckbox from './../NestedCheckbox';
import Avatar from './../Avatar';
import Button from './../ui/Button';
import Responsive from './../Responsive';

const entityTypes = {
  ADMIN: __('Admin'),
  STAFF: __('Staff'),
  STUDENT: __('Student')
};

const styles = {
  modal: {
    borderRadius: '12px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.429rem',
    color: '#000000',
    fontWeight: 700
  },
  icon: {
    fontSize: '1.5rem',
    cursor: 'pointer',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  primaryButton: {
    backgroundColor: '#084FFF',
    color: '#FFF',
    fontWeight: 'normal',
    marginLeft: '0.75rem'
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    fontWeight: 'normal',
    fontSize: '1rem'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  block: {
    boxShadow: '-1px 0px 0px rgba(0, 0, 0, 0.1)',
    height: '2.5rem',
    margin: '2rem 0',
    display: 'flex',
    alignItems: 'center'
  }
};

const DisabledModal = props => (
  <BetaModal
    id="SelectPermissions"
    header={__('Error')}
    type
    onClose={() => { props.onClose(); }}
    content={
      <div>
        <div style={{ padding: '0.625rem 1.5rem' }}>
          <span style={{ fontSize: '1rem', fontWeight: 400 }}>{__('It is not possible to add this person because he is not yet registered in ClassApp. Confirm the registration and add it later.')}</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            round
            text={__('Ok')}
            onClick={() => { props.onClose(); }}
          />
        </div>
      </div>
    }
    size="tiny"
  />
);

@inject('store', 'api', 'client')
@graphql(gql`
query OrganizationEntitiesQuery($id: ID!, $limit: Int, $offset: Int, $search: String, $entityType: [EntityType], $orderBy: [OrganizationEntitiesOrder]) {
  node(id: $id) @connection(key: "Organization", filter: ["id"]) {
    ... on Organization {
      id: dbId
      fullname
      entities(limit: $limit, offset: $offset, search: $search, type: $entityType, orderBy: $orderBy) {
        totalCount
        nodes {
          id: dbId
          fullname
          picture {
            uri
            id: dbId
            key
          }
          type
          invisible
          disabled
          seeAll
          users {
            totalCount
            nodes {
              id: dbId
              fullname
              phone
              email
              confirmEmail
              confirmPhone
              tags {
                nodes {
                  id: dbId
                  name
                }
              }
            }
          }
          addresses {
            nodes {
              type
              address
              tags {
                nodes {
                  id: dbId
                  name
                }
              }
              user {
                id: dbId
                fullname
                email
                phone
              }
            }
          }
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: ownProps.store.currentOrganization.id,
      limit: 50,
      offset: 0,
      search: '',
      entityType: ownProps.entityType,
      orderBy: {
        column: "CREATED",
        direction: "DESC"
      }
    }
  })
})
export default class SelectUserModal extends Responsive {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedItems: [],
      searchText: ''
    };
  }

  selectedUsers = () => {
    const { selectedItems } = this.state;
    const selectedUsers = [];
    selectedItems.forEach((entity) => {
      if (!entity.users.nodes.length > 0) return;
      entity.users.nodes.forEach((user, userIndex) => {
        const selectedUserIndex = selectedUsers.findIndex(selectedUser => selectedUser.id === user.id);
        if (selectedUserIndex !== -1) {
          selectedUsers[selectedUserIndex].entities.push({ id: entity.id, userIndex });
        } else {
          selectedUsers.push({ id: user.id, entities: [{ id: entity.id, userIndex }] });
        }
      });
    });
    return selectedUsers;
  };

  componentDidUpdate = () => {
    const { setLoadingToFalse, data } = this.props;
    if (setLoadingToFalse && !((data.loading && !data.node) || !data.node)) {
      setLoadingToFalse();
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { data, selectedUsers, currentEntityId, hiddenEntities } = nextProps;
    if (!selectedUsers || data.loading) return;
    const formattedSelectedUsers = selectedUsers.map(item => item.user);

    if (data.node.entities.nodes.length > 0) {
      const selectedItems = [];
      data.node.entities.nodes.forEach((entity) => {
        if (hiddenEntities.includes(entity.id) || entity.users.totalCount === 0) return;
        entity.users.nodes.forEach((user) => {
          if (formattedSelectedUsers.some(selectedUser => selectedUser.id === user.id)) {
            const entityCopy = cloneDeep(entity);
            entityCopy.users.nodes = [user];
            selectedItems.push(entityCopy);
          }
        });
      });
      this.state.selectedItems.forEach((entity) => {
        if (!selectedItems.some(e => e.id === entity.id)) selectedItems.push(entity);
      });
      if (!this.state.nonRemovedUsers) this.setState({ selectedItems, nonRemovedUsers: cloneDeep(toJS(selectedUsers)) });
      else this.setState({ selectedItems });
    }
    this.setState({ loading: false });
  }

  isSomeEntityUsersSelected = (entity, users) => entity.users.totalCount > 0 && entity.users.nodes.every(item => users.some(user => user.id === item.id))

  onSubmit = (users) => {
    const { store, onSubmit } = this.props;

    let finalUsers = users;

    finalUsers = finalUsers.filter(user => user.address);
    finalUsers = uniqBy(finalUsers, 'address');

    onSubmit(finalUsers);
    store.appends.pop();
  }

  refreshQuery = () => {
    const { data, store } = this.props;
    const { searchText } = this.state;

    data.refetch({
      id: store.currentOrganization.id,
      limit: 100,
      offset: 0,
      search: searchText,
      entityType: this.props.entityType,
      orderBy: {
        column: 'CREATED',
        direction: 'DESC'
      }
    });
  }

  handleMainItemToggleCheckbox = (data, checkboxStatus) => {
    const selectedItems = cloneDeep(this.state.selectedItems);
    let nonRemovedUsers = this.state.nonRemovedUsers;
    const foundSelectedEntity = selectedItems.findIndex(item => item.id === data.id);
    const { checked, indeterminate } = checkboxStatus;

    if (checked || indeterminate) {
      if (foundSelectedEntity !== -1) selectedItems.splice(foundSelectedEntity, 1);
      const selectedUsers = this.selectedUsers();
      data.users.nodes.forEach((user) => {
        const foundSelectedUser = selectedUsers.find(selectedUser => selectedUser.id === user.id);
        if (!foundSelectedUser) return;
        foundSelectedUser.entities.forEach(({ id: entityId }) => {
          const entityIndex = selectedItems.findIndex(item => item.id === entityId);
          if (entityIndex === -1) return;
          const entity = selectedItems[entityIndex];
          if (entity.users.nodes.length > 1) {
            const userIndex = entity.users.nodes.findIndex(selectedUser => selectedUser.id === user.id);
            entity.users.nodes.splice(userIndex, 1);
          } else {
            selectedItems.splice(entityIndex, 1);
          }
        });
        if (this.state.nonRemovedUsers) nonRemovedUsers = nonRemovedUsers.filter(({ user: nonRemovedUser }) => nonRemovedUser.id !== user.id);
      });
    } else {
      selectedItems.push(data);
    }

    if (this.state.nonRemovedUsers) this.setState({ selectedItems, nonRemovedUsers });
    else this.setState({ selectedItems });
  }

  handleSubItemToggleCheckbox = (data, userId) => {
    const selectedItems = cloneDeep(this.state.selectedItems);
    let nonRemovedUsers = this.state.nonRemovedUsers;
    const selectedUsers = this.selectedUsers();
    const foundSelectedUser = selectedUsers.find(user => user.id === userId);

    if (foundSelectedUser) {
      foundSelectedUser.entities.forEach(({ id: entityId, userIndex }) => {
        const entityIndex = selectedItems.findIndex(item => item.id === entityId);
        const entity = selectedItems[entityIndex];
        if (entity.users.nodes.length > 1) {
          entity.users.nodes.splice(userIndex, 1);
        } else {
          selectedItems.splice(entityIndex, 1);
        }
        if (this.state.nonRemovedUsers) nonRemovedUsers = nonRemovedUsers.filter(({ user: nonRemovedUser }) => nonRemovedUser.id !== userId);
      });
    } else {
      const selectedEntity = selectedItems.find(item => item.id === data.id);
      if (selectedEntity) {
        const userData = data.users.nodes.find(user => user.id === userId);
        selectedEntity.users.nodes.push(userData);
      } else {
        const newItem = cloneDeep(data);
        newItem.users.nodes = [data.users.nodes.find(user => user.id === userId)];
        selectedItems.push(newItem);
      }
    }

    if (this.state.nonRemovedUsers) this.setState({ selectedItems, nonRemovedUsers });
    else this.setState({ selectedItems });
  }

  handleTextChange = (e) => {
    clearTimeout(this.lastRequestId);
    // eslint-disable-next-line no-unused-vars
    let id;
    this.setState({ searchText: e.target.value, loading: true });
    // eslint-disable-next-line no-multi-assign
    this.lastRequestId = id = setTimeout(() => this.refreshQuery(), 500);
  };

  handleClick = () => {
    const { selectedItems, nonRemovedUsers } = this.state;

    let hasPendingAddress = false;
    const users = [];
    selectedItems.map(item =>
      item.users.nodes.map((user) => {
        if (!((user.phone && user.confirmPhone) || (user.email && user.confirmEmail))) {
          hasPendingAddress = true;
        }
        return ['phone', 'email'].map(address =>
          users.push({
            address: user[address],
            tags: user.tags,
            type: address.toUpperCase(),
            user
          })
        );
      })
    );
    if (nonRemovedUsers && nonRemovedUsers.length) {
      nonRemovedUsers.forEach((user) => {
        users.push(user);
      });
    }

    if (hasPendingAddress) {
      return this.props.store.appends.push(
        <BetaModal
          id="PendingUserModal"
          actions={[
            <Button
              data-action="cancel"
              round transparent
              text={__('Back')}
              onClick={() => this.props.store.appends.pop()}
            />,
            <Button
              data-action="submit"
              round
              text={__('Continue')}
              onClick={() => {
                this.props.store.appends.pop();
                return this.onSubmit(users);
              }}
            />
          ]}
          header={__('There is a pending confirmation')}
          content={
            <div>
              <span>
                {__('There are people with unconfirmed users. They will not be added instantly, but will receive an invite to the Channel. Do you want to proceed anyway?')}
              </span>
            </div>
          }
        />
      );
    }

    return this.onSubmit(users);
  }

  renderItem = (data) => {
    const { fullname, type, picture, users } = data;

    const hasUsers = users.nodes.length > 0;

    const mainItem = {
      key: data.id,
      label: !hasUsers ?
        <div style={{ display: 'flex' }}>
          <Avatar alt={fullname} src={picture && picture.uri} avatar style={{ width: '40px', height: '40px' }} />
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem' }}>
            <span style={{ color: '#787878' }}>{fullname}</span>
            <span style={{ color: '#A8A8A8' }}>{`${entityTypes[type]} ${__('(Pending)')}`}</span>
          </div>
        </div>
        :
        <div style={{ display: 'flex' }}>
          <Avatar alt={fullname} src={picture && picture.uri} avatar style={{ width: '40px', height: '40px' }} />
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem' }}>
            <span style={{ color: '#000' }}>{fullname}</span>
            <span style={{ color: '#666665' }}>{entityTypes[type]}</span>
          </div>
        </div>,
      onMainItemClick: checkBoxStatus => this.handleMainItemToggleCheckbox(data, checkBoxStatus),
      onDisabledClick: () => { this.props.store.appends.push(<DisabledModal onClose={() => { this.props.store.appends.pop(); }} />); }
    };
    const subItems = users.nodes.map((user) => {
      const confirmedContacts = user && ((user.phone && user.confirmPhone) || (user.email && user.confirmEmail));
      const label = confirmedContacts ?
        (<div style={{ marginTop: '5px', overflow: 'hidden', display: 'flex' }}>
          <Tag style={{ overflow: 'hidden', width: 'unset' }} name={user.fullname} className="transparent" />
          <span style={{ color: '#00000099', marginLeft: 16, display: 'flex', alignItems: 'center' }}>{(user.email && utils.renderLongText(user.email || user.phone, 27)) || (user.phone && utils.formatPhone(user.phone, true))}</span>
        </div>)
        :
        (<Popup
          content={
            <div>{__('This user has not confirmed any of his contacts.')}</div>
          }
          trigger={
            <div style={{ marginTop: '5px', overflow: 'hidden' }}>
              <Tag style={{ overflow: 'hidden', width: 'unset' }} name={user.fullname} className="disabled" />
            </div>
          }
        />);
      const foundSelectedUser = this.selectedUsers().findIndex(selectedUser => selectedUser.id === user.id);
      const isSubItemChecked = foundSelectedUser !== -1;

      return {
        key: user.id,
        checked: isSubItemChecked,
        label,
        onSubItemClick: () => this.handleSubItemToggleCheckbox(data, user.id),
      };
    }
    );

    return (
      <NestedCheckbox
        mainItem={mainItem}
        subItems={subItems}
      />
    );
  }

  render() {
    const { selectedItems, loading } = this.state;
    const { data, currentEntityId, notFoundMessage, hiddenEntities } = this.props;
    const isDisabled = selectedItems.length < 1;
    const selectedUsers = this.selectedUsers();

    const selectedLabel = selectedUsers.length > 0 ?
      (selectedUsers.length === 1 ? __('1 selected') : __('%s selected', selectedUsers.length)) : __('No user selected');

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    return (
      <BetaModal
        header={__('Select users')}
        id="SelectUser"
        closeOnRootNodeClick={false}
        fullScreen={this.isMobile()}
        fixHeader={this.isMobile()}
        fixActions={this.isMobile()}
        actions={
          <div style={{ ...styles.footer, width: '100%' }}>
            <p style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '16px', marginLeft: this.isMobile() ? '0px' : '10px', maxWidth: this.isMobile() ? '120px' : 'unset', textAlign: 'initial' }}>
              {selectedLabel}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                round
                text={__('Add')}
                style={{ padding: '16px 24px', lineHeight: '16px' }}
                disabled={isDisabled}
                icon={{ name: 'check' }}
                onClick={() => { this.handleClick(); }}
              />
            </div>
          </div>
        }
        onClose={() => this.props.store.appends.pop()}
        content={
          <div style={this.isMobile() ? { paddingLeft: '10px', paddingRight: '10px', paddingBottom: '70px' } : {}}>
            <Input
              type="search"
              onChange={this.handleTextChange}
              placeholder={__('Type the staff\'s name...')}
              onClear={() => this.setState({ searchText: '' })}
            />
            {loading ? (
              <div style={{ height: this.isMobile() ? 'unset' : '400px' }}>
                <Loading width={40} height={40} style={{ marginTop: '1rem', alignItems: 'flex-start' }} />
              </div>
            )
              : <div
                style={{
                  marginTop: '1rem',
                  overflow: 'auto',
                  height: this.isMobile() ? 'unset' : '400px'
                }}
              >
                {data.node.entities.nodes.length > 0 ?
                  data.node.entities.nodes.filter(entity => !hiddenEntities.includes(entity.id))
                    .map(item => this.renderItem(item))
                  : <span style={{ color: '#000', fontSize: '16px', marginLeft: '10px' }}>{notFoundMessage || __('Not found')}</span>
                }
              </div>}
          </div>
        }
        size="tiny"
      />
    );
  }
}
