/* eslint-disable indent */
import React from 'react';
import { Loader, Popup } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import omit from 'lodash/omit';
import cookie from 'react-cookie';

// Utils
import { __ } from '../../i18n';
import * as utils from '../../utils';

// Components
import Avatar from '../Avatar';
import BetaModal from './Modal';
import Button from './Button';
import Input from './Input';
import Loading from '../../components/ui/Loading';
import Responsive from '../Responsive';
import Paginator from '../../components/Paginator';

// Styles
import '../../assets/css/ui/ListEntityModal.module.scss';

const entityTypes = {
  ADMIN: __('Admin'),
  STAFF: __('Staff'),
  STUDENT: __('Student')
};

const ArrivedPendingEntitiesQuery = gql`
query ArrivedPendingEntitiesQuery($id: ID!, $limit: Int, $offset: Int, $search: String, $entityType: [EntityType!], $orderBy: [OrganizationEntitiesWithArrivedOrder], $groupId: [Int!]) {
  node(id: $id) @connection(key: "Organization", filter: ["id"]) {
    ... on Organization {
      id: dbId
      fullname
      tags{
        nodes{
        id
        name
        checked
      }
      }
      arrivedConfiguration{
        id: dbId
        tags{
          nodes{
            id
            name
            checked
          }
        }
      }
      entities: entitiesWithArrived(pendingEntities: true, limit: $limit, offset: $offset, search: $search, entityType: $entityType, orderBy: $orderBy, groupId:$groupId) {
        totalCount
        pageInfo{
          hasNextPage
        }
        nodes {
          id: dbId
          fullname
          shortname
          seeAll
          code
          disabled
          type
          picture {
            uri
            id: dbId
            key
          }
          organization {
            id: dbId
            type
          }
        }
      }
    }
  }
}
`;

@inject('store', 'api', 'client')
@graphql(ArrivedPendingEntitiesQuery, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: ownProps.store.currentOrganization.id,
      limit: 100,
      offset: 0,
      search: '',
      entityType: ownProps.entityType,
      orderBy: {
        column: "CREATED",
        direction: "DESC"
      },
      groupId: ownProps.group ? [ownProps.group] : []
    }
  })
})

/**
 * @param {String} notFoundMessage Message to be shown when there is no result in search
 * @param {String[]} entityType Type of the entities to be listed
 */
export default class ListEntityModal extends Responsive {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      loading: false
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { data } = nextProps;
    const { loading } = this.state;
    if (!data.loading && loading) {
      this.setState({ loading: false });
    }
  }

  handleTags = (data) => {
    const arrivedTags = data.node.arrivedConfiguration.tags.nodes;
    const organizationTags = data.node.tags.nodes;
    arrivedTags.map((tag) => {
      const index = organizationTags.findIndex(item => item.id === tag.id);
      if (index !== -1) organizationTags[index].checked = true;
    });
    return organizationTags;
  }
  /**
   * Deal with data calling props onSubmit or executing another action
   */
  onSubmit = () => {
    const { client, store, totalCount, entityType, router, arrivedEntityId, group } = this.props;
    this.setState({ loading: true });

    const variables = {
      id: store.currentOrganization.id,
      limit: totalCount,
      offset: 0,
      search: '',
      entityType,
      orderBy: {
        column: 'CREATED',
        direction: 'DESC'
      },
      groupId: group ? [group] : []
    };
    client.query({ query: ArrivedPendingEntitiesQuery, variables })
      .then(({ data }) => {
        const tags = this.handleTags(data);
        const content = this.isMobile() ? __('Hello, how are you?\n\n\nIn the last few days, we have been using "Arrived" to streamline our students\' check-out process.\n\nWith this new tool from ClassApp, you will have more agility, security and organization to pick up your children from school.\n\n\n\nTo use it, there is no secret:\n\n\nUpdate the ClassApp on your phone\nClick on the car icon (🚘) for the first time and you will be directed to a quick registration\n\nAfter that, every time you access "Arrived", you will see the list of students available for pickup.\n\nWhen you arrive in front of the school, you will click on the "I arrived" button below the name of the student you will be picking up.\n\n\n\nAnd that\'s it! Just one click, and your child will be delivered in no time!\n\n\nIf you are responsible for more than one student, when you arrive you must click on the "I arrived" button of both, so we understand that you have come to pick up more than one student.')
          : __('Arrived template message (translated)');

        const message = {
          subject: __('Arrived: agility and safety to pick up your child!'),
          content,
          recipients: [...data.node.entities.nodes],
          tags
        };
        cookie.save(
          'ArrivedTemplateMessageSubject',
          message.subject,
          {
            path: '/',
            domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''),
            maxAge: 60 * 60 * 24 // 1 day
          }
        );
        cookie.save(
          'ArrivedTemplateMessageTags',
          message.tags,
          {
            path: '/',
            domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''),
            maxAge: 60 * 60 * 24 // 1 day
          }
        );
        store.template = {
          Arrived: {
            content: message.content,
            recipients: message.recipients
          }
        };
      }).then(() => {
        store.appends.pop();
        router.push(`/entities/${arrivedEntityId}/messages/add?template=Arrived`);
      }).catch((e) => {
        console.error(e);
        this.setState({ loading: false });
        utils.clearSnackbar(store);
        store.snackbar = { active: true, message: __('Falha ao requisitar a lista completa de pessoas'), success: false, dismissAfter: 5000 };
      });
  }

  /**
   * For refetching the entities list after search text changes
   */
  refreshQuery = () => {
    const { data, store } = this.props;
    const { searchText } = this.state;

    data.refetch({
      id: store.currentOrganization.id,
      limit: 100,
      offset: 0,
      search: searchText,
      entityType: this.props.entityType,
      orderBy: {
        column: 'CREATED',
        direction: 'DESC'
      }
    });
  }

  handleTextChange = (e) => {
    clearTimeout(this.lastRequestId);
    const lastRequestId = setTimeout(() => this.refreshQuery(), 500);
    this.setState({ searchText: e.target.value, lastRequestId, loading: true });
  };

  renderItem = (data) => {
    const { fullname, type, picture } = data;
    return (
      <div
        key={data.id}
        className="listEntityItem"
      >
        <div>
          <Avatar alt={fullname} src={picture && picture.uri} avatar style={{ width: '40px', height: '40px' }} />
          <div className="itemInfo">
            <span style={{ color: '#000' }}>{fullname}</span>
          </div>
        </div>
      </div>
    );
  }

  loadMore = () => {
    const { data } = this.props;
    if (!data.node.entities.pageInfo.hasNextPage) return;
    data.fetchMore({
      variables: {
        offset: data.node.entities.nodes.length
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult || fetchMoreResult.node.entities.nodes.length === 0) {
          return previousResult;
        }
        fetchMoreResult.node.entities.nodes =
          [...previousResult.node.entities.nodes, ...fetchMoreResult.node.entities.nodes];
        return fetchMoreResult;
      },
    });
  }

  onClose = () => {
    if (this.props.callBack) this.props.callBack();
    this.props.store.appends.pop();
  }

  render() {
    const { arrivedEntityId, data, notFoundMessage, totalCount, store: { currentUser } } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;
    const footerStyles = {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      padding: this.isMobile() ? 0 : '14px 15px 15px'
    };

    const modalMessage = totalCount > 1 ? __('Do you want to send a message to these **%s people**?', totalCount) :
      __('Do you want to send a message to this person?', totalCount);

    const isSubmitDisabled = !currentUser.entities.some(entity => entity.id === arrivedEntityId) && !currentUser.isMaster;

    return (
      <BetaModal
        header={__('Have not used "Arrived"')}
        id="ListEntity"
        closeOnRootNodeClick={false}
        fullScreen={this.isMobile()}
        fixHeader={this.isMobile()}
        fixActions={this.isMobile()}
        actions={
          <div style={{ ...footerStyles, width: '100%' }}>
            <Button
              floated="left"
              round
              transparent
              text={__('Cancel')}
              onClick={() => this.onClose()}
            />
            {isSubmitDisabled ?
              <Popup
                trigger={
                  <div>
                    <Button
                      id="disabledButtonWithHover" // Workaround for disabled button not triggering the Popup
                      round
                      text={__('Write message')}
                      style={{ backgroundColor: 'rgb(194, 219, 255)' }}
                      icon={{ name: 'envelope' }}
                    />
                  </div>
                }
                content={<span>{__('O seu perfil precisa ter acesso ao canal Cheguei para enviar essa mensagem')}</span>}
                hideOnScroll
              />
              :
              <Button
                round
                text={__('Write message')}
                style={{ padding: '16px 24px', lineHeight: '16px' }}
                icon={{ name: 'envelope' }}
                onClick={() => { this.onSubmit(); }}
              />
            }
          </div>
        }
        onClose={() => this.onClose()}
        content={
          <div style={this.isMobile() ? { paddingLeft: '10px', paddingRight: '10px', paddingBottom: '70px' } : {}}>
            <p style={{ fontSize: this.isMobile() ? 14 : 16, paddingLeft: 10 }}>
              {utils.parseFormattedTranslation({
                text: modalMessage,
                props: {
                  style: {
                    fontWeight: 'bold'
                  }
                }
              })}
            </p>
            <Input
              type="search"
              onChange={this.handleTextChange}
              placeholder={__('Enter a person\'s name...')}
              onClear={() => this.setState({ searchText: '' })}
              style={{ marginTop: 25 }}
            />

            <div
              style={{
                marginTop: '1rem',
                overflow: 'auto',
                height: this.isMobile() ? 'unset' : '400px'
              }}
            >
              {this.state.loading ? <Loading width={40} height={40} style={{ marginTop: '1rem', alignItems: 'flex-start', height: 40 }} />
                : [
                  (
                    data.node.entities.nodes.length > 0 ?
                      data.node.entities.nodes.map(item => this.renderItem(item))
                      : <span key="empty" style={{ color: '#000', fontSize: '16px', marginLeft: '10px' }}>{notFoundMessage || __('Not found')}</span>
                  ),
                  <Paginator
                    key={2}
                    isMobile={false}
                    source={data.node.entities.nodes}
                    pagination={{
                      loading: data.loading,
                      totalCount: data.node.entities.totalCount,
                      hasNextPage: data.node.entities.pageInfo.hasNextPage,
                      query: null,
                      noAllOption: true,
                    }}
                    onLoadMore={this.loadMore}
                  />
                ]}
            </div>
          </div>
        }
        size="tiny"
      />
    );
  }
}
