import React from 'react';
import JSONInput from 'react-json-editor-ajrm';

import localePt from 'react-json-editor-ajrm/locale/pt';
import localeEn from 'react-json-editor-ajrm/locale/en';

import Error from '../../components/ui/Error';

import { __ } from '../../i18n';

import '../../assets/css/ui/Error.module.scss';

export default class JSONEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      __error: ''
    };
  }

  getRef = () => this.JSONInput;

  handleOnChange = ({ error, jsObject }) => {
    if (error) {
      this.setState({ __error: error && error.reason });
    } else {
      this.setState({ __error: '' });
      if (!jsObject || Array.isArray(jsObject)) {
        this.props.onChange(null);
      } else {
        this.props.onChange(jsObject);
      }
    }
  }

  hasError = () => !!this.state.__error;

  render() {
    const {
      style, label, labelStyle, lang, view, value,
    } = this.props;

    const { __error } = this.state;

    return (
      <div style={style}>
        <span style={labelStyle}>{label}</span>
        <div style={{ border: '1px solid rgba(0, 0, 0, 0.16)', borderColor: (__error || this.props.error) ? '#BF2600' : 'rgba(0, 0, 0, 0.16)', borderRadius: '8px', padding: '5px', marginTop: '8px' }}>
          <JSONInput
            ref={(ref) => {
              this.JSONInput = ref;
            }}
            id="a_unique_id"
            placeholder={value || [__('Write the JSON code here')]}
            theme="light_mitsuketa_tribute"
            confirmGood={false}
            viewOnly={view}
            onKeyPressUpdate={false}
            onChange={this.handleOnChange}
            locale={lang === 'pt-BR' ? localePt : localeEn}
            height="260px"
            width="100%"
            style={{
              warningBox: {
                display: 'none'
              }
            }}
          />
        </div>
        <Error text={this.props.error || __error} />
      </div>
    );
  }
}
