import React from 'react';
import omit from 'lodash/omit';
import PhoneNumber from 'awesome-phonenumber';
import { allCountries } from '../../lib/countries';
import { __ } from '../../i18n';
import Dropdown from '../../components/Dropdown';
import Input from '../../components/ui/Input';
import Error from '../../components/ui/Error';

export default class PhoneInput extends React.Component {
  constructor(props) {
    super(props);

    let country = props.country || '+55';

    // autoDetectCountry flag makes component select country code based on browser's language
    if (props.autoDetectCountry) {
      const navigatorCountries = navigator.languages.filter(c => c.indexOf('-') === 2).map(c => c.substring(3, 5).toLowerCase());
      const supportedCountries = allCountries().map(c => c.code);

      let navigatorCountry = null;
      for (const c of navigatorCountries) {
        const countryIndex = supportedCountries.indexOf(c);
        if (countryIndex >= 0) {
          navigatorCountry = allCountries()[countryIndex].value;
          break;
        }
      }

      country = navigatorCountry || country;
    }

    let phone = props.phone || '';


    if (props.defaultValue) {
      const pn = new PhoneNumber('+' + props.defaultValue, 'ZZ');
      country = '+' + PhoneNumber.getCountryCodeForRegionCode(pn.getRegionCode());
      phone = pn.getNumber('significant');
    }

    this.state = {
      country,
      phone,
      valid: true
    };
  }

  onPhoneBlur = pn => (e) => {
    const { onPhoneBlur, onBlur, optional } = this.props;

    this.setState({ valid: pn.isMobile() || (!!optional && e.target.value === ''), phone: e.target.value }, () => {
      if (onPhoneBlur) onPhoneBlur(this.state.phone);
      if (onBlur) onBlur(this.state.country + this.state.phone);
    });
  }

  onPhoneChange = (phone) => {
    const { onPhoneChange, onChange } = this.props;

    this.setState({ phone: phone.replace(/[^0-9 ()+-]+$/, '') }, () => {
      if (onPhoneChange) onPhoneChange(this.state.phone);
      if (onChange) onChange(this.state.phone.length === 1 ? this.state.phone : new PhoneNumber(this.state.country + this.state.phone, 'ZZ').getNumber('international'));
    });
  }

  onCountryChange = (country) => {
    const { onCountryChange, onChange } = this.props;

    this.setState({ country }, () => {
      if (onCountryChange) onCountryChange(this.state.country);
      if (onChange && this.state.phone) onChange(this.state.country + this.state.phone);
    });
  }

  countries = allCountries().map((c, key) => ({
    key,
    text: `${this.props.flag ? c.flag : ''} ${c.text}`,
    value: `${c.value}`,
    'data-code': `${c.value}`,
    self: c,
  }))

  render() {
    const newProps = omit(this.props,
      ['defaultValue', 'country', 'phone', 'onCountryChange', 'onPhoneChange', 'onChange', 'onPhoneBlur', 'onBlur', 'hasError', 'error']);

    const pn = new PhoneNumber(this.state.country + this.state.phone, 'ZZ');

    const selectedCountry = this.countries.find(country => country.value === this.state.country);

    return (
      <div>
        <div style={{ display: 'flex' }}>
          <div style={this.props.newStyleEnabled ? { display: 'flex', alignItems: 'center', border: this.props.red ? '1px solid rgba(191, 38, 0, 1)' : '1px solid rgba(34,36,38,.15)', borderRadius: '8px 0px 0px 8px', padding: '0px 16px' } : {}}>
            <Dropdown
              className={'phone-dropdown'}
              scrolling
              value={this.state.country}
              options={this.countries}
              trigger={this.props.flag ? <span>{`${selectedCountry.self.flag} ${selectedCountry.value}`}</span> : undefined}
              onChange={(e, { value }) => this.onCountryChange(value)
              }
              style={this.props.newStyleEnabled ? {
                display: 'flex',
                border: 'none',
              } : {
                margin: '8px 8px 12px 0',
                border: this.props.hasError ? '1px solid' : '1px solid rgba(34,36,38,.15)',
                backgroundColor: '#FFF',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'baseline',
              }}
            />
          </div>
          <div style={{ width: '100%' }}>
            <Input
              red={this.props.red}
              icon={{ before: { name: 'mobile' } }}
              value={this.state.phone}
              placeholder={this.props.placeholder || __('Phone')}
              onChange={e => this.onPhoneChange(e.target.value)}
              onBlur={this.onPhoneBlur(pn)}
              {...newProps}
            />
          </div>
        </div>
        <Error text={this.props.error} />
      </div>
    );
  }
}
