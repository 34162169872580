import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

import '../../assets/css/ui/Button.module.scss';
import MessageApprovalIcon from './MessageApprovalIcon';

/**
 * @typedef {{
 *  src?: string,
 *  alt?: string,
 *  style?: React.CSSProperties,
 *  size?: number,
 *  className?: string,
 *  customColor?: string,
 *  round?: boolean,
 *  text: string,
 *  submit?: boolean,
 *  onClick: (args: any) => {},
 *  transparent?: boolean,
 *  blue?: boolean,
 *  disabled?: boolean,
 *  frontIcon?: { name: import('semantic-ui-react').SemanticICONS, style: React.CSSProperties },
 *  full?: boolean,
 *  href?: string,
 *  icon?: {
 *    name: import('semantic-ui-react').SemanticICONS,
 *    style: React.CSSProperties,
 *    size: import('semantic-ui-react/dist/commonjs/elements/Icon/Icon').IconSizeProp
 *  },
 *  id?: string,
 *  key?: string,
 *  loading?: boolean,
 *  noMarginIcon?: boolean,
 *  onKeyDown?: () => {},
 *  paywall?: boolean,
 *  red?: boolean,
 *  secondary?: boolean,
 *  gray?: boolean,
 *  messageApprovalIcon?: string,
 * }} Props
 * @extends {Component<Props>}
 */
export default class Button extends Component {
  render() {
    const {
      blue,
      disabled,
      frontIcon,
      full,
      href,
      icon,
      id,
      key,
      submit,
      loading,
      noMarginIcon,
      onClick,
      onKeyDown,
      paywall,
      red,
      round,
      secondary,
      style,
      text,
      transparent,
      gray,
      messageApprovalIcon,
      ...rest
    } = this.props;

    if (loading) {
      return (
        <button
          className={`button icon${transparent ? ' transparent' : ''}${red ? ' red' : ''}${secondary ? ' secondary' : ''}${blue ? ' blue' : ''}${round ? ' round' : ''}${full ? ' full-width' : ''}${gray ? ' gray' : ''}`}
          type={submit ? 'submit' : 'button'}
          onClick={onClick}
          disabled={loading}
          style={{ width: '7.143rem', ...style }}
          key={key}
          {...rest}
        >
          <div data-testid="loading-button" className="ui active inline loader small" />
        </button>
      );
    }

    if (paywall) {
      return (
        <button
          className={`button${transparent ? ' transparent' : ''}${red ? ' red' : ''}${secondary ? ' secondary' : ''}${round ? ' round' : ''}${full ? ' full-width' : ''}${icon && text ? ' icon-text' : icon ? ' icon' : ''}${paywall ? ' paywall' : ''}${gray ? ' gray' : ''}`}
          type={submit ? 'submit' : 'button'}
          style={style}
          onClick={onClick}
          disabled={disabled}
          key={key}
          {...rest}
        >
          {icon ? <Icon className={icon.name} style={icon.style} /> : null}
          {text || null}
          {// @ts-ignore
            <Icon name="stars" style={{ color: '#A5A5A5', marginLeft: '0.286rem' }} />
          }
        </button>
      );
    }

    if (href) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          className={`link button${transparent ? ' transparent' : ''}${red ? ' red' : ''}${secondary ? ' secondary' : ''}${blue ? ' blue' : ''}${round ? ' round' : ''}${full ? ' full-width' : ''}${icon && text ? ' icon-text' : icon ? ' icon' : ''}${noMarginIcon ? ' no-margin-icon' : ''}${gray ? ' gray' : ''}${disabled ? ' disabled ' : ''}`}
          href={disabled ? undefined : href}
          id={id}
          rel="noopener noreferrer"
          role="button"
          style={style}
          target="_blank"
        >
          {icon ? <Icon className={icon.name} style={icon.style} size={icon.size} /> : null}
          {text || null}
          {frontIcon ? <Icon name={frontIcon.name} style={{ ...frontIcon.style, marginLeft: '0.286rem' }} /> : null}
        </a>
      );
    }

    return (
      <button
        id={id}
        className={`button${transparent ? ' transparent' : ''}${red ? ' red' : ''}${secondary ? ' secondary' : ''}${blue ? ' blue' : ''}${round ? ' round' : ''}${full ? ' full-width' : ''}${icon && text ? ' icon-text' : icon ? ' icon' : ''}${noMarginIcon ? ' no-margin-icon' : ''}${gray ? ' gray' : ''}`}
        type={submit ? 'submit' : 'button'}
        style={style}
        onClick={onClick}
        disabled={disabled}
        key={key}
        onKeyDown={onKeyDown}
        {...rest}
      >
        {icon ? <Icon className={icon.name} style={icon.style} /> : null}
        {messageApprovalIcon ? <MessageApprovalIcon style={{ marginRight: 6 }} /> : null}
        {text || null}
        {frontIcon ? <Icon name={frontIcon.name} style={{ ...frontIcon.style, marginLeft: '0.286rem' }} /> : null}
      </button>
    );
  }
}
