import React from 'react';
import { Image } from 'semantic-ui-react';
import * as utils from '../../utils';

export default class EmptyImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { urlError: false };
  }

  render() {
    const { url, title, subtitle } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'cemter',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            src={utils.asset(url)}
            style={{ ...this.props.style, maxHeight: 200 }}
            onError={() => this.setState({ urlError: true })}
          />
        </div>
        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p
            data-testid="title"
            style={{ fontSize: '24px', fontWeight: 'bold' }}
          >
            {title}
          </p>
        </div>
        {subtitle && (
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p style={{ fontSize: '16px', fontWeight: 'normal' }}>{subtitle}</p>
          </div>
        )}
      </div>
    );
  }
}
