import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { uniqBy } from 'lodash';
import { inject, observer } from 'mobx-react';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import SelectUserModal from './SelectUserModal';
import Button from './Button';
import Tag from './Tag';

const styles = {
  icon: {
    fontSize: '1.5rem',
    cursor: 'pointer',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  block: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    height: '2.5rem',
    margin: '2rem 0',
    display: 'flex',
    alignItems: 'center'
  }
};

@inject('store')
@observer
export default class SelectUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedItems: [],
      searchText: ''
    };
  }

  onDeleteUser = (id) => {
    let selectedItems = [...this.props.selectedItems];
    const foundSelected = selectedItems.map((item, index) => { if (item.user && item.user.id === id) return index; }).filter(i => i !== undefined);
    selectedItems = selectedItems.filter((item, index) => !foundSelected.includes(index));
    this.props.onDelete(selectedItems);
  }

  render() {
    const { selectedItems, onSubmit, error, store, disabled, buttonText, notFoundMessage, buttonStyles, hiddenEntities, entityType } = this.props;
    const getSelectedUsers = selectedItems.filter(node => node.user);

    const removeDuplicateUsers = uniqBy(getSelectedUsers, 'user.id');

    const isMaster = store && store.currentUser && store.currentUser.isMaster;

    return (
      <div id="SelectUser">
        {removeDuplicateUsers.length > 0 ?
          <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0', flexWrap: 'wrap' }}>
            {removeDuplicateUsers.map(({ user, original }) => (
              user && ((user.phone && user.confirmPhone) || (user.email && user.confirmEmail)) ?
                (
                  user.email && user.confirmEmail ?
                    <Popup
                      content={
                        <div>{user.email}</div>
                      }
                      position="top center"
                      trigger={
                        <div>
                          <Tag style={{ margin: '0.4rem' }} name={user.fullname} className="transparent" onDelete={((utils.hasEntityInCurrentOrganization(store, ['ADMIN']) || !original || isMaster) ? () => { if (!disabled) this.onDeleteUser(user.id); } : null)} />
                        </div>
                      }
                    />
                    :
                    <Tag style={{ margin: '0.4rem' }} name={user.fullname} className="transparent" onDelete={((utils.hasEntityInCurrentOrganization(store, ['ADMIN']) || !original || isMaster) ? () => { if (!disabled) this.onDeleteUser(user.id); } : null)} />
                )
                : (
                  <Popup
                    content={
                      <div style={{}}>{__('This user has not confirmed any of his contacts.')}</div>
                    }
                    trigger={
                      <div>
                        <Tag style={{ margin: '0.4rem' }} name={user.fullname} className="disabled" onDelete={((utils.hasEntityInCurrentOrganization(store, ['ADMIN']) || !original || isMaster) ? () => { if (!disabled) this.onDeleteUser(user.id); } : null)} />
                      </div>
                    }
                  />
                )
            )
            )}
          </div>
          :
          <div style={styles.block}>
            <span style={{ marginLeft: '24px', color: 'rgba(0, 0, 0, 0.6)' }}>{__('No user selected')}</span>
          </div>}
        {
          !this.state.loading && !disabled ?
            <Button
              transparent
              round
              text={buttonText || __('Select users')}
              disabled={this.state.loading}
              icon={{ name: 'plus', style: error ? { color: '#BF2600' } : null }}
              style={error ? { border: '1px solid #BF2600', color: '#BF2600', ...buttonStyles } : buttonStyles || null}
              // eslint-disable-next-line react/jsx-curly-spacing
              onClick={(event) => {
                event.persist();
                if (this.state.loading) {
                  return null;
                }
                this.setState({ loading: true }, () => {
                  this.props.store.appends.push(
                    <SelectUserModal
                      onSubmit={onSubmit}
                      setLoadingToFalse={() => this.setState({ loading: false })}
                      currentEntityId={this.props.currentEntityId}
                      notFoundMessage={notFoundMessage}
                      selectedUsers={removeDuplicateUsers}
                      hiddenEntities={hiddenEntities || []}
                      entityType={entityType || ['ADMIN', 'STAFF']}
                    />);
                }
                );
              }}
            /> :
            <Button
              transparent
              round
              text={buttonText || __('Select users')}
              disabled
              icon={{ name: 'plus', style: error ? { color: '#BF2600' } : null }}
              style={error ? { border: '1px solid #BF2600', color: '#BF2600', ...buttonStyles } : buttonStyles || null}
              // eslint-disable-next-line react/jsx-curly-spacing
              onClick={() => null}
            />
        }

        {error && (
          <div className={error.className ? 'error-warning ' + error.className : 'error-warning'} style={error && error.style && error.style}>
            <Icon className="circle exclamation" />
            <span>{typeof error !== 'string' && error.text ? error.text : error}</span>
          </div>
        )}
      </div>
    );
  }
}
