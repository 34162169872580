import React from 'react';
import { Icon } from 'semantic-ui-react';

import '../../assets/css/ui/Error.module.scss';

export default class Error extends React.Component {
  render() {
    const { text, style } = this.props;

    if (!text) {
      return null;
    }

    return (
      <div data-testid="error-ui" className="error-warning" style={style}>
        <Icon className="circle exclamation" />
        <span>{text}</span>
      </div>
    );
  }
}
