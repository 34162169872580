import React from 'react';

/**
 * @typedef {{
 *  style?: React.CSSProperties,
 *  width?: number | string,
 *  height?: number | string,
 *  fillColor?: string,
 * }} Props
 *
 * @param {Props} param0
 * @returns {JSX.Element}
 */
function MessageApprovalIcon({
  style, width, height, fillColor
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '23'}
      height={height || '16'}
      fill="none"
      viewBox="0 0 23 16"
      style={style}
    >
      <path
        fill={fillColor || '#373B3F'}
        d="M2.3 1.675h13.8c.61 0 1.15.54 1.15 1.15v1.186c.18 0 .359-.036.575-.036.18 0 .359.036.575.036V2.825c0-1.258-1.043-2.3-2.3-2.3H2.3a2.293 2.293 0 00-2.3 2.3v9.2a2.27 2.27 0 002.3 2.3h10.637a5.31 5.31 0 01-.755-1.15H2.3c-.647 0-1.15-.503-1.15-1.15V5.7l6.325 4.672a2.972 2.972 0 003.414 0l.61-.467c.037-.575.144-1.15.324-1.69l-1.617 1.222a1.783 1.783 0 01-2.048 0L1.15 4.263V2.825c0-.61.503-1.15 1.15-1.15zm15.525 4.6c3.09 0 5.03 3.378 3.45 6.037a3.942 3.942 0 01-3.45 2.013c-3.127 0-5.067-3.342-3.486-6.037.718-1.222 2.012-2.013 3.486-2.013zm0 9.2c3.953 0 6.468-4.313 4.456-7.762-.935-1.582-2.624-2.588-4.456-2.588-3.99 0-6.505 4.312-4.492 7.762a5.147 5.147 0 004.492 2.588zm2.407-6.72a.609.609 0 00-.826 0l-2.156 2.192-1.043-1.042a.609.609 0 00-.826 0 .609.609 0 000 .826l1.437 1.438a.609.609 0 00.827 0l2.587-2.588a.609.609 0 000-.826z"
      />
    </svg>
  );
}

export default MessageApprovalIcon;
