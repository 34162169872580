import React from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import omit from 'lodash/omit';

import '../../assets/css/ui/Dropdown.module.scss';

export default class MultipleDropdown extends React.Component {
  render() {
    const newProps = omit(this.props, [
      'fluid',
      'multiple',
      'search',
      'selection',
      'minCharacters',
      'error',
      'icon',
    ]);

    const { error, className, reference } = this.props;
    return (
      <div id="MultipleDropdown">
        <Dropdown
          className={`multiple-dropdown ${error ? 'dropdown-error' : ''} ${className || ''}`}
          {...newProps}
          ref={reference}
          fluid
          multiple
          search
          icon={<Icon className="angle down dropdown-icon" />}
          selection
          minCharacters={0}
        />

        {error && (
          <div
            className="error-warning"
            style={error && error.style && error.style}
          >
            <Icon className="circle exclamation" />
            <span>
              {typeof error !== 'string' && error.text ? error.text : error}
            </span>
          </div>
        )}
      </div>
    );
  }
}
