import React from 'react';
import { Segment, Popup, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import { uniq } from 'lodash';

import Form from '../ui/Form';

import ColoredCheckbox from '../ColoredCheckbox';
import Button from '../ui/Button';

import { __ } from '../../i18n';

const getTypeOptions = () => [
  { text: __('Single Select'), value: 'SELECT', content: <div data-value="SELECT"><Icon className={'inside_dropdown'} name="dot circle" style={{ color: '#0080ff' }} />{__('Single Select')}</div> },
  { text: __('Multiple Select'), value: 'CHECK', content: <div data-value="CHECK"><Icon className={'inside_dropdown'} name="check square" style={{ color: '#0080ff' }} />{__('Multiple Select')}</div> },
  { text: __('Text'), value: 'TEXT', content: <div data-value="TEXT"><Icon className={'inside_dropdown'} name="font" style={{ color: '#0080ff' }} />{__('Text')}</div> }
];

const styles = {
  label: {
    margin: '0px 0px 8px 0px'
  },
  field: {
    padding: '10px',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    height: '47px',
    borderRight: '0px',
    cursor: 'pointer'
  }
};

@inject('store')
@observer
export default class FieldForm extends Form {
  defaultValues = {
    name: '',
    type: 'SELECT',
    options: [''],
    initial: ''
  }

  rules = {
    name: ['required', values => this.validateContent('name', values)],
    options: [values => this.validateContent('options', values)]
  }

  validateContent = (name, values) => {
    if (name === 'name' && values.trim().length <= 0) throw new Error(__('Name cannot be empty'));

    if (name === 'name' && values.trim().length > 255) throw new Error(__('Name cannot be longer than 255 characters'));

    if (name === 'options' && this.state.values.type === 'TEXT') return true;

    if (name === 'options' && (uniq(values.map(value => value && value.trim())).length !== values.length)) throw new Error(__('All fields must be different'));

    const labeledOptions = this.state.values.options.filter(options => options && options.trim().length > 0).length;

    if (name === 'options' && ((this.state.values.options.length !== labeledOptions))) throw new Error(__('Options cannot be empty'));

    if (name === 'options' && this.state.values.options.length === 0 && labeledOptions === 0) throw new Error(__('Fields must have at least one valid option'));
  }

  onTypeChange = (e, data) => {
    const values = this.state.values;

    if (data.value === 'TEXT' || data.value === 'SELECT') {
      values.initial = '';
    } else if (data.value === 'CHECK') {
      values.initial = [];
    }

    if (data.value !== 'TEXT' && values.type === 'TEXT') {
      values.options = [''];
    }

    this.setState({ values },
      () => this.onInputChange(e, data)
    );
  }

  renderPreview = () => {
    const { values } = this.state;

    if (values.type === 'TEXT') {
      return (<Form.FormTextArea
        rows="5"
        value={values.initial || ''}
        style={{ marginTop: '5px' }}
      />);
    }

    return values.options.map((option, i) =>
      (option ?
        <div key={i} style={{ marginTop: '5px' }}>
          <ColoredCheckbox
            radio={values.type === 'SELECT'}
            label={option}
            color="#084FFF"
            style={{ wordBreak: 'break-all' }}
            checked={values.type === 'CHECK' ? values.initial && values.initial.indexOf(`${i}`) > -1 : `${i}` === values.initial}
          />
        </div>
        :
        null));
  }

  handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === 'Enter') {
      this.setState({ autofocus: true }, () => {
        if (this.state.values.options.find(option => option === '') === undefined) {
          this.pushValue('options', '');
        }
      });
    }
  };

  renderOptions = () =>
    this.state.values.options.map((option, i) =>
      <div style={{ marginTop: '1rem' }}>
        <Form.Input
          key={i}
          inputStyle={{ height: '47px', paddingRight: '3rem' }}
          placeholder={__('Option')}
          actionPosition="left"
          onKeyPress={(e) => this.handleKeypress(e)}
          icon={{
            after: {
              name: 'times',
              link: true,
              'data-action': 'delete-option',
              'data-params': i,
              style: { fontSize: '16px' },
              onClick: () => this.popValue('options', `${i}`)
            }
          }}
          actionLeft={
            (this.state.values.type === 'CHECK') ?
              this.isMobile() ?
                (<Segment data-action="toggle-check-initial" data-params={i} compact className="segment-button" style={{ ...styles.field }} onClick={() => this.toggleValue('initial', `${i}`)}>
                  <ColoredCheckbox
                    color="#084FFF"
                    checked={this.state.values.initial && this.state.values.initial.indexOf(`${i}`) > -1}
                  />
                </Segment>)
                :
                (<Popup
                  content={__('Initially checked')}
                  trigger={
                    <Segment data-action="toggle-check-initial" data-params={i} compact className="segment-hover-button" style={{ ...styles.field }} onClick={() => this.toggleValue('initial', `${i}`)}>
                      <ColoredCheckbox
                        color="#084FFF"
                        checked={this.state.values.initial && this.state.values.initial.indexOf(`${i}`) > -1}
                      />
                    </Segment>
                  }
                />)
              :
              this.isMobile() ?
                <Segment
                  data-action="toggle-select-initial" data-params={i} compact className="segment-button" style={{ ...styles.field }}
                  onClick={this.state.values.initial === `${i}` ? () => this.setValue('initial', '') : () => this.setValue('initial', `${i}`)}
                >
                  <ColoredCheckbox
                    radio
                    color="#084FFF"
                    checked={this.state.values.initial === `${i}`}
                  />
                </Segment>
                :
                <Popup
                  content={__('Initially checked')}
                  trigger={
                    <Segment
                      data-action="toggle-select-initial" data-params={i} compact className="segment-hover-button" style={{ ...styles.field }}
                      onClick={this.state.values.initial === `${i}` ? () => this.setValue('initial', '') : () => this.setValue('initial', `${i}`)}
                    >
                      <ColoredCheckbox
                        radio
                        color="#084FFF"
                        checked={this.state.values.initial === `${i}`}
                      />
                    </Segment>
                  }
                />
          }
          name="options"
          data-params={i}
          value={option}
          onChange={e => this.onItemChange(i, { name: 'options', value: e.target.value })}

          autoFocus={this.state.autofocus && this.state.values.options.length === i + 1}
        />
      </div>
    )

  render() {
    const { values, errors } = this.state;
    const { submitButton, cancelButton, submitButtonIcon, title } = this.props;
    const hasOptions = values.options && values.options.filter(o => o).length > 0;

    const actionButtonsLeft = [<Button
      data-action="cancel"
      round
      transparent
      floated="left"
      text={cancelButton && cancelButton.text}
      onClick={() => this.props.store.appends.pop()}
    />];
    const actionButtonsRight = [<Button
      data-action="submit"
      round
      icon={submitButtonIcon && { name: submitButtonIcon }}
      text={submitButton && submitButton.text}
      onClick={this.handleSubmit}
    />];

    return (
      <Form
        id="FieldForm"
        {...this.props}
        actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null}
        actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
        onSubmit={this.handleSubmit}
        paddingHeader={this.isMobile()}
        hasDivider={this.isMobile()}
        header={this.isMobile() ? {
          title: '',
          onClose: () => this.props.store.appends.pop(),
          invertCloseButton: true,
          headerItem: <Button
            data-action="submit"
            round
            text={submitButton.text}
            onClick={this.handleSubmit}
            style={{ height: '40px', width: '100px', justifyContent: 'center', alignItems: 'center', display: 'flex', padding: '12px' }}
          /> } : null}
      >
        <div style={{ display: 'flex', flexDirection: this.isMobile() ? 'column' : 'row', padding: this.isMobile() ? '24px' : '0px' }}>
          <div style={{ width: this.isMobile() ? '100%' : '50%', marginBottom: '2rem' }}>
            {this.isMobile() && <p className="bold" style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '24px' }}>{title}</p>}

            <div>
              <Form.Input
                label={__('Name')}
                labelStyle={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '12px' }}
                placeholder={__('Name')}
                name="name"
                red={errors && errors.name && errors.name !== 'Error'}
                value={values.name}
                autoFocus
                style={{ width: '100%' }}
                error={errors && errors.name}
                onChange={e => this.onInputChange(e, { name: 'name', value: e.target.value })}
              />
            </div>

            <div style={{ marginTop: '1rem' }}>
              <Form.Dropdown
                className={'form-dropdown'}
                name="type"
                label={__('Field type')}
                icon={'chevron down'}
                compact
                selection
                placeholder="--"
                value={values.type}
                options={getTypeOptions()}

                onChange={(e, { value }) => this.onTypeChange(e, { name: 'type', value })}
              />
            </div>

            { values.type === 'TEXT' ?
              <div style={{ marginTop: '1rem' }}>
                <Form.FormTextArea
                  style={{ resize: 'none' }}
                  label={__('Default text')}
                  autoHeight
                  labelStyle={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', marginBottom: '12px' }}
                  placeholder={__('Write the position or other informations...')}
                  name="initial"
                  value={values.initial}
                  onChange={e =>
                    this.onInputChange(e, { name: 'initial', value: e.target.value })
                  }
                />
              </div>
              :
              <div style={{ marginTop: '1rem' }}>
                <p className="bold" style={{ fontWeight: 'bold', fontSize: '16px', lineHeight: '19px' }}>{__('Options')}</p>
                {this.renderOptions()}
                {errors && errors.options ?
                  <div className="error-warning-red" >
                    <Icon data-value="error" name="exclamation circle" />
                    {errors.options}
                  </div>

                  :
                  null
                }
                <Button
                  type="submit"
                  data-action="add-option"
                  round transparent
                  style={{ marginTop: '16px' }}
                  icon={{ name: 'plus' }}
                  text={__('Add option')}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ autofocus: true }, () => {
                      if (this.state.values.options.find(option => option === '') === undefined) {
                        this.pushValue('options', '');
                      }
                    });
                  }}
                />

              </div>
            }
          </div>
          <div style={{ width: this.isMobile() ? '100%' : '50%', marginBottom: '2rem', display: 'flex', alignItems: 'center', justifyContent: this.isMobile() ? 'unset' : 'center' }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', marginLeft: this.isMobile() ? '0px' : '50px' }}>
              <div style={{ color: (hasOptions || values.name) ? '#000' : '#a0a0a0', fontSize: '16px', fontWeight: 'bold', marginBottom: this.isMobile() ? '20px' : '40px' }}><Icon name="eye" />{__('Report Field Preview')}</div>
              <p className="bold" style={{ fontSize: '16px' }}>{values.name || ' '}</p>
              {this.renderPreview()}
            </div>
          </div>
        </div>
      </Form>
    );
  }
}
