import React from 'react';
import { List } from 'semantic-ui-react';
import FriendlyDate from '../../FriendlyDate';
import { Link } from 'react-router';
import Avatar from '../../Avatar';
import { __ } from '../../../i18n';
import { Strong } from './NotificationItem.style';

const directNotificationFormta = (direct) => (
  <span>
    {__('New message from')} <Strong>{direct.fromEntity}</Strong>{' '}
    {__('in chat with channel')} <Strong>{direct.toEntity}</Strong>
  </span>
);

export const messageMap = (moment, direct) => ({
  MESSAGE: __('sent a message to'),
  REPLY: __('replied to'),
  REPLY_LOG: __('changed reply sent to'),
  COMMITMENT: __('confirmed your commitment request'),
  COMMITMENT_REMIND: __('is waiting for your answer on a commitment'),
  SURVEY: __('responded your survey'),
  SURVEY_PENDING: __('is waiting for your answer on the survey'),
  LOG: moment ? __('changed moment sent to') : __('changed a message'),
  LIKE: __('liked your moment'),
  MOMENT: __('shared a moment with'),
  CHARGE: __('paid charge from'),
  CHARGE_PENDING: __('is waiting for your charge payment'),
  FORM: __('filled a form from'),
  FORM_PENDING: __('is waiting for your answer on a form'),
  ATTENDANCE_CREATED: directNotificationFormta(direct),
  DIRECT_MESSAGE_CREATED: directNotificationFormta(direct),
});

export const NotificationItem = ({
  notification,
  to,
  onClick,
  locale,
  ...rest
}) => {
  const directNotificationsType = [
    'ATTENDANCE_CREATED',
    'DIRECT_MESSAGE_CREATED',
  ];

  return (
    <List.Item
      className="clsp-userNot"
      style={{ padding: '8px 24px', border: 0 }}
      as={Link}
      to={to}
      onClick={onClick}
      {...rest}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <div style={{ height: 48, width: 48, marginRight: 12 }}>
            <Avatar
              avatar
              src={
                notification.fromEntity.picture &&
                notification.fromEntity.picture.uri
              }
              alt={notification.fromEntity.fullname}
              style={{ width: 32, height: 32 }}
            />
            <Avatar
              avatar
              src={
                notification.toEntity.picture &&
                notification.toEntity.picture.uri
              }
              alt={notification.toEntity.fullname}
              style={{
                width: 32,
                height: 32,
                transform: 'translate(50%, -50%)',
              }}
            />
          </div>
        </div>
        <div>
          <div>
            {!directNotificationsType.includes(notification.notificationType) &&
              notification.fromEntity.fullname}{' '}
            <span style={{ color: '#6C6C6C' }}>
              {
                messageMap(
                  notification.message && notification.message.moment,
                  {
                    fromEntity: notification.fromEntity.fullname,
                    toEntity: notification.toEntity.fullname,
                  },
                )[notification.notificationType]
              }
            </span>
            {(notification.notificationType !== 'LOG' &&
              !directNotificationsType.includes(
                notification.notificationType,
              )) ||
            (notification.message && notification.message.moment)
              ? ' ' + notification.toEntity.fullname
              : ''}
          </div>
          <FriendlyDate
            date={notification.created}
            locale={locale}
            style={{ fontSize: 12, color: '#6C6C6C', marginTop: 4 }}
          />
        </div>
      </div>
    </List.Item>
  );
};
