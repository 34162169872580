import React from 'react';

const Loading = ({ width, height, style, ...rest }) => (
  <div
    className="loading-ui"
    data-testid="loading-ui"
    style={{ ...style }}
    {...rest}
  >
    <div className="loader-ui" style={{ width, height }} />
  </div>
);

export default Loading;
