import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Loader, Icon } from 'semantic-ui-react';
import omit from 'lodash/omit';

import '../../assets/css/ui/Modal.module.scss';
import ElementModifier from '../ElementModifier';

const styles = {
  modal: {
    borderRadius: '12px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1.75rem 1rem 0.75rem 1.5rem',
    borderRadius: '0 0 12px 12px',
  },
  title: {
    fontSize: '1.429rem',
    color: '#000000',
    fontWeight: 700
  },
  toastTitle: {
    fontSize: '1.143rem',
    margin: 'auto',
  },
  icon: {
    fontSize: '20px',
    cursor: 'pointer',
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  primaryButton: {
    backgroundColor: '#084FFF',
    color: '#FFF',
    fontWeight: 'normal',
    marginLeft: '0.75rem'
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    fontWeight: 'normal',
    fontSize: '1rem'
  },
  button: {
    padding: '1rem 1.5rem'
  }
};

/**
 * @typedef {{
 *  closeOnBack?: boolean,
 *  onClose: () => {},
 *  fullScreen?: boolean,
 *  fixHeader?: boolean,
 *  store: { modals: string[] },
 *  dimmer?: string,
 *  header?: string | JSX.Element,
 *  headerItem?: string | JSX.Element,
 *  headerItemStyle?: React.CSSProperties,
 *  invertCloseButton?: boolean,
 *  content?: string | JSX.Element,
 *  actions?: boolean | JSX.Element | JSX.Element[],
 *  trigger?: boolean,
 *  loading?: boolean,
 *  scrolling?: boolean,
 *  toast?: boolean,
 *  hasDivider?: boolean,
 *  id: string | number,
 *  onClickTimesClose?: () => {},
 *  marginBottom?: boolean,
 *  spaceFooter?: boolean,
 *  noContentPadding?: boolean,
 *  portalHeader?: boolean,
 *  styleScrollingContent?: React.CSSProperties,
 *  web?: boolean,
 *  heightSize?: string | number,
 *  cssTags?: boolean,
 *  fixActions?: boolean,
 *  subHeader?: null,
 *  className?: string,
 * }} Props
 * @extends {React.Component<Props>}
 */
@inject('store') @observer
export default class BetaModal extends React.Component {
  static Header = Modal.Header;
  static Content = Modal.Content;
  static Actions = Modal.Actions;

  componentDidMount = () => {
    const { closeOnBack, onClose } = this.props;
    if (closeOnBack) {
      window.addEventListener('popstate', () => {
        onClose();
      }, false);
    }
  }

  onMount = () => {
    const { fullScreen, fixHeader } = this.props;
    if (fixHeader && !fullScreen) {
      document.body.classList.add('hidden-modal-scroll');
    }
    this.props.store.modals.push('');
    this.mounted = true;
  }

  onUnmount = () => {
    const { fullScreen, fixHeader } = this.props;
    const modals = this.props.store.modals;
    const l = modals.length;

    if (l) {
      document.body.classList.add('dimmable', 'dimmed', 'scrolling');
      if (this.props.dimmer) document.body.classList.add(this.props.dimmer);
    }
    if (fixHeader && !fullScreen) {
      document.body.classList.remove('hidden-modal-scroll');
    }
    if (this.mounted) {
      this.props.store.modals.pop();
      this.mounted = false;
    }
  }

  // Prevent multiple unmount (weird semantic bug)
  mounted = false;

  // @ts-ignore
  renderHeader = (header, headerItem, onClose, onClickTimesClose, invertCloseButton, subHeader = null, headerItemStyle) => (
    <div>
      <div style={{ ...styles.header, padding: this.props.hasDivider ? '12px' : '20px 24px', borderBottom: this.props.toast || this.props.hasDivider ? '1px solid #f1f3f5' : null, ...headerItemStyle }}>
        {invertCloseButton &&
          // @ts-ignore
          (onClickTimesClose || onClose) && <Icon name="times close" style={{ ...styles.icon, position: 'absolute' }} onClick={() => { onClickTimesClose ? onClickTimesClose() : onClose(); }} />
        }
        {header ?
          <span
            style={
              this.props.toast ?
                { ...styles.title, ...styles.toastTitle } :
                this.props.invertCloseButton ?
                  { ...styles.title, margin: 'auto' } :
                  { ...styles.title }}
          >
            {header}
          </span>
          :
          <div />
        }
        <div className="header-items" role="button">
          {
            headerItem
          }
          {!invertCloseButton &&
            // @ts-ignore
            (onClickTimesClose || onClose) && <Icon name="times close" style={styles.icon} onClick={() => { onClickTimesClose ? onClickTimesClose() : onClose(); }} />
          }
        </div>
      </div>
      {subHeader}
    </div>

  );

  render() {
    const {
      header, headerItem, headerItemStyle, invertCloseButton, content, actions, trigger,
      loading, scrolling, toast, fullScreen, marginBottom, spaceFooter, noContentPadding,
      portalHeader, styleScrollingContent, web, heightSize, fixHeader, cssTags, fixActions,
      subHeader, className,
    } = this.props;
    const newProps = omit(this.props,
      ['header', 'headerItem', 'invertCloseButton', 'content', 'actions', 'store', 'loading', 'scrolling', 'toast', 'noContentPadding', 'web', 'className']);

    if (loading) {
      return (<Modal
        open
        onMount={this.onMount}
        onUnmount={this.onUnmount}
        {...newProps}
      >
        <Loader active />
        {this.props.children}
      </Modal>);
    }

    const fixActionsStyle = fixActions ? {
      borderRadius: '0px',
      position: 'absolute',
      bottom: '0px',
      width: '100%'
    } : {};

    return (
      <div className="testeee">
        <Modal
          size="tiny"
          className={`beta-modal${toast ? ' toast' : ''}${fullScreen ? ' full-screen scrolling' : ''}${noContentPadding ? ' no-content-padding' : ''}${marginBottom ? ' margin-bottom' : ''}${web ? ' web' : ''} ${cssTags || ''} ${fixHeader ? ' fix-header' : ''} ${className || ''}`}
          style={{ ...styles.modal, height: heightSize }}
          open={(!trigger) ? true : undefined}
          onMount={this.onMount}
          onUnmount={this.onUnmount}
          closeOnDimmerClick={false}
          {...newProps}
        >
          {
            portalHeader ?
              <Modal.Header style={{ ...styles.header, padding: 0, borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }} />
              :
              (!!header || !!headerItem) && this.renderHeader(header, headerItem, this.props.onClose, this.props.onClickTimesClose, invertCloseButton, subHeader, headerItemStyle || {})
          }

          {!!content && <Modal.Content style={{ ...styleScrollingContent }} className={scrolling ? 'scrolling' : ''}>{content}</Modal.Content>}


          {actions === true ? <Modal.Actions style={{ ...styles.footer, height: fullScreen ? 'calc(10vh)' : 'unset' }} className={`modal-actions-footer${spaceFooter ? ' space-between' : ''}`} /> :
            !!actions && <Modal.Actions style={{ ...styles.footer, ...fixActionsStyle }} className={`modal-actions-footer${spaceFooter ? ' space-between' : ''}`}>{actions}</Modal.Actions>
          }
          {this.props.children}
          {!(fullScreen || toast) && <ElementModifier element={document.body} newClassName="paddingOnModalDimmer" />}
        </Modal>
      </div>
    );
  }
}
