import React from 'react';

import { Icon, Popup } from 'semantic-ui-react';
import '../../assets/css/ui/ReviewedBy.module.scss';
import { __ } from '../../i18n';
/**
 * @typedef {{
 *  entity?: string,
 *  status?: 'rejected' | 'approved' | null,
 * }} Props
 * @extends {React.Component<Props>}
 */
export default class ReviewedBy extends React.Component {
  render() {
    const { status, entity } = this.props;
    const isApproved = status === 'approved';
    const statusMessage = isApproved ? __('Approved') : __('Not approved');
    const popupMessage = isApproved ? __('Approved by %s', entity) : __("Wasn't approved by %s", entity);

    return (
      <Popup
        trigger={(
          <div id="ReviewedBy" className={`background ${status}`}>
            <Icon
              data-testId="ReviewedBy"
              className={`icon ${status}`}
              name={`thumbs ${isApproved ? 'up' : 'down'}`}
              fitted
            />
            <p className={`text ${status}`}>{statusMessage}</p>
          </div>
        )}
        content={<div dangerouslySetInnerHTML={{ __html: popupMessage }} />}
        hideOnScroll
        position="top center"
        style={{ color: isApproved ? '#00A656' : '#BF2600' }}
      />
    );
  }
}
