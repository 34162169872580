import React from 'react';
import { Icon, Loader, Grid, Popup } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';

import { __ } from '../../i18n';
import BetaModal from './../ui/Modal';
import Responsive from './../Responsive';
import ColoredCheckbox from './../ColoredCheckbox';
import Button from './../ui/Button';

const scopeSettings = {
  messages: {
    typename: ['MESSAGE'],
    read: {
      requirements: []
    },
    delete: {
      requirements: [
        {
          type: 'messages',
          action: 'read'
        }
      ],
      info: __('Only moments can be deleted for now')
    }
  },
  reports: {
    typename: ['REPORT'],
    read: {
      requirements: []
    },
    createUpdate: {
      requirements: [
        {
          type: 'reports',
          action: 'read'
        }
      ]
    },
    delete: {
      requirements: [
        {
          type: 'reports',
          action: 'read'
        }
      ]
    }
  },
  entities: {
    typename: ['ENTITY', 'GROUP'],
    read: {
      requirements: []
    },
    createUpdate: {
      requirements: [
        {
          type: 'entities',
          action: 'read'
        }
      ]
    },
    delete: {
      requirements: [
        {
          type: 'entities',
          action: 'read'
        }
      ],
      info: __('Only groups can be deleted')
    }
  },
  dashboard: {
    typename: ['DASHBOARD'],
    read: {
      requirements: [
        {
          type: 'entities',
          action: 'read'
        }
      ]
    }
  },
  integration: {
    typename: ['INTEGRATION'],
    read: {
      requirements: []
    }
  },
  payments: {
    typename: ['PAYMENT'],
    read: {
      requirements: []
    }
  },
  accounts: {
    typename: ['ACCOUNT'],
    read: {
      requirements: []
    },
    createUpdate: {
      requirements: [
        {
          type: 'accounts',
          action: 'read'
        }
      ]
    },
    delete: {
      requirements: [
        {
          type: 'accounts',
          action: 'read'
        }
      ]
    }
  },
  organization: {
    typename: ['ORGANIZATION'],
    update: {
      requirements: []
    }
  },
  forms: {
    typename: ['FORM'],
    read: {
      requirements: []
    },
    createUpdate: {
      requirements: [
        {
          type: 'forms',
          action: 'read'
        }
      ]
    },
    delete: {
      requirements: [
        {
          type: 'forms',
          action: 'read'
        }
      ]
    }
  },
  links: {
    typename: ['LINK'],
    read: {
      requirements: []
    },
    createUpdate: {
      requirements: [
        {
          type: 'links',
          action: 'read'
        }
      ]
    },
    delete: {
      requirements: [
        {
          type: 'links',
          action: 'read'
        }
      ]
    }
  },
};

const styles = {
  modal: {
    borderRadius: '12px'
  },
  popup: {
    textAlign: 'center'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.429rem',
    color: '#000000',
    fontWeight: 700
  },
  icon: {
    fontSize: '1.5rem',
    cursor: 'pointer',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  primaryButton: {
    backgroundColor: '#084FFF',
    color: '#FFF',
    fontWeight: 'normal',
    marginLeft: '0.75rem'
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    fontWeight: 'normal',
    fontSize: '1rem'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  block: {
    boxShadow: '-1px 0px 0px rgba(0, 0, 0, 0.1)',
    height: '2.5rem',
    margin: '2rem 0',
    display: 'flex',
    alignItems: 'center'
  }
};

export class SelectPermissionsModal extends Responsive {
  constructor(props) {
    super(props);
    const scope = cloneDeep(props.scope) || {
      messages: {
        read: false,
        delete: false
      },
      reports: {
        read: false,
        createUpdate: false,
        delete: false
      },
      entities: {
        read: false,
        createUpdate: false,
        delete: false
      },
      dashboard: {
        read: false
      },
      integration: {
        read: false
      },
      payments: {
        read: false
      },
      accounts: {
        read: false,
        createUpdate: false,
        delete: false
      },
      organization: {
        update: false
      },
      forms: {
        read: false,
        createUpdate: false,
        delete: false
      },
    };

    this.state = {
      loading: true,
      selectedItems: [],
      scope,
      searchText: ''
    };
  }

  onToggleCheckbox = (type, action) => {
    const { scope } = this.state;

    scope[type][action] = !scope[type][action];
    if (scope[type][action] && scopeSettings[type][action].requirements && scopeSettings[type][action].requirements.length) {
      scopeSettings[type][action].requirements.forEach((req) => {
        scope[req.type][req.action] = true;
      });
    } else if (!scope[type][action]) {
      Object.keys(scopeSettings).forEach(t => ['read', 'createUpdate', 'update', 'delete'].forEach((a) => {
        if (!scopeSettings[t][a]) return;

        const reqs = scopeSettings[t][a].requirements.filter(req => req.type === type && req.action === action);

        if (!reqs.length) return;

        scope[t][a] = false;
      }));
    }

    this.setState({ scope });
  }

  handleClick = () => {
    const { store } = this.props;
    const { scope } = this.state;
    this.props.onSubmit(scope);
    store.appends.pop();
  }

  _approveMessageContent = (item, action, hasMessageApproval) => {
    if (
      [scopeSettings.entities.read, scopeSettings.messages.read].includes(scopeSettings[item.field][action])
      && hasMessageApproval
      ) {
      return {
        info: __('Mandatory permission for employees who can approve messages')
      };
    }
    return null;
  }

  renderPermissions = (disabled) => {
    const { scope } = this.state;
    const { store } = this.props;
    const features = store.currentOrganization && store.currentOrganization.features;
    const confCharge = store.currentOrganization && store.currentOrganization.confCharge;
    const isApprover = scope && scope.entities && scope.entities.contentApprover;
    const hasMessageApproval = store.currentOrganization && store.currentOrganization.unleashStatus && store.currentOrganization.unleashStatus.approve_message
      && store.currentOrganization.plan === 'PREMIUM' && isApprover; //Unleash implementation
    let options = [
      { title: features && features.messageManager && features.messageManager === 'BASIC' ? __('Organization moments') : __('Organization messages and moments'), field: 'messages', icon: 'envelope' },
      { title: __('Reports'), field: 'reports', icon: 'list layout' },
      { title: __('Entities, Channels and Groups'), field: 'entities', icon: 'users' },
      { title: __('Organization dashboard'), field: 'dashboard', icon: 'tachometer alt' },
      { title: __('Integrations'), field: 'integration', icon: 'puzzle piece' },
      { title: __('Payments: Balance and Charges Dashboards'), field: 'payments', icon: 'donate' },
      { title: __('Payments: Banks and Withdrawals'), field: 'accounts', icon: 'university' },
      { title: __('Forms'), field: 'forms', icon: 'poll h' },
      { title: __('Organization configurations'), field: 'organization', icon: 'cog' },
      { title: __('Accesses'), field: 'links', icon: 'box open full' }
    ];

    if (features && features.messageManager && features.messageManager === 'BASIC' && !(features && features.moments)) options = options.filter(o => o.field !== 'messages');
    if (!(features && features.reports)) options = options.filter(o => o.field !== 'reports');
    if (!(features && features.api)) options = options.filter(o => o.field !== 'integration');
    if (!((features && features.charges) && confCharge)) options = options.filter(o => o.field !== 'payments' && o.field !== 'accounts');
    if (!(features && features.links)) options = options.filter(o => o.field !== 'links');
    if (!(features && features.forms)) options = options.filter(o => o.field !== 'forms');

    return (
      <div>
        <Grid divided={false} style={{ margin: '0px 0px' }} >
          <Grid.Row columns={4} style={{ padding: 0, marginBottom: '30px' }}>
            <Grid.Column width={1} />
            <Grid.Column width={6} />
            {
              [__('Visualizing'), __('Adding and Editing'), __('Deleting')].map((title, key) =>
                <Grid.Column textAlign="center" verticalAlign="bottom" width={3} key={key} style={{ padding: '0px' }}>
                  <p>{title}</p>
                </Grid.Column>)
            }
          </Grid.Row>
          {
            options.map((item, k) =>
              <Grid.Row columns={5} key={k} style={{ padding: 0, marginBottom: k === options.length - 1 ? null : '30px' }}>
                <Grid.Column textAlign="left" verticalAlign="middle" width={1}>
                  <p style={{ paddingLeft: '2em' }}><Icon name={item.icon} style={{ color: '#999999' }} /></p>
                </Grid.Column>
                <Grid.Column textAlign="left" verticalAlign="middle" width={6}>
                  <p style={{ paddingLeft: '2em' }}>{item.title}</p>
                </Grid.Column>
                {
                  ['read', ['createUpdate', 'update'], 'delete'].map((key) => {
                    let action = typeof key === 'string' ? key : key[0];

                    if (typeof key !== 'string' && !scopeSettings[item.field][action]) {
                      action = key[1];
                    }

                    return (
                      !scopeSettings[item.field][action] ?
                        (
                          <Grid.Column textAlign="center" verticalAlign="middle" width={3}>
                            {
                              disabled ?
                                (
                                  <Popup
                                    trigger={<Icon name={'minus filled circle'} />}
                                    content={__('Unavailable')}
                                    hideOnScroll
                                    basic
                                  />
                                )
                                :
                                null
                            }
                          </Grid.Column>
                        ) :
                        (<Grid.Column textAlign="center" verticalAlign="middle" width={3} key={action}>
                          {
                            disabled ?
                              (
                                scope[item.field][action] ?
                                  (
                                    <Popup
                                      trigger={<Icon style={{ color: '#099268' }} name={'check filled circle'} />}
                                      content={__('Enabled')}
                                      hideOnScroll
                                      basic
                                    />
                                  )
                                  :
                                  (
                                    <Popup
                                      trigger={<Icon style={{ color: '#F03E3E' }} name={'times fille  d circle'} />}
                                      content={__('Disabled')}
                                      hideOnScroll
                                      basic
                                    />
                                  )
                              )
                              :
                              (
                                scopeSettings[item.field][action].info || (!!this._approveMessageContent(item, action, hasMessageApproval)) ?
                                  <Popup
                                    trigger={<ColoredCheckbox
                                      radio
                                      radioChecked
                                      color="#084FFF"
                                      checked={scope[item.field][action]}
                                      data-action={`select-${item.field}`}
                                      data-value={action}
                                      onClick={() => this.onToggleCheckbox(item.field, action)}
                                      disabled={!!this._approveMessageContent(item, action, hasMessageApproval)}
                                    />}
                                    position="top center"
                                    style={styles.popup}
                                    content={scopeSettings[item.field][action].info || this._approveMessageContent(item, action, hasMessageApproval).info}
                                  />
                                  :
                                  <ColoredCheckbox
                                    radio
                                    radioChecked
                                    color="#084FFF"
                                    checked={scope[item.field][action]}
                                    data-action={`select-${item.field}`}
                                    data-value={action}
                                    onClick={() => this.onToggleCheckbox(item.field, action)}
                                  />
                              )
                          }
                        </Grid.Column>)
                    );
                  })
                }
              </Grid.Row>)
          }

        </Grid>
      </div>

    );
  }

  renderMobileTableItem = (item, key, disabled, scope) => {
    const { store } = this.props;
    const isApprover = scope && scope.entities && scope.entities.contentApprover;
    const hasMessageApproval = store.currentOrganization && store.currentOrganization.unleashStatus && store.currentOrganization.unleashStatus.approve_message
      && store.currentOrganization.plan === 'PREMIUM' && isApprover; //Unleash implementation

    let action = typeof key === 'string' ? key : key[0];

    if (typeof key !== 'string' && !scopeSettings[item.field][action]) {
      action = key[1];
    }

    if (!scopeSettings[item.field][action]) return <div style={{ width: '20%' }} />;

    return (
      <div style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={action}>
        {disabled ?
          (scope[item.field][action]
            ? (<Popup trigger={<Icon style={{ color: '#099268' }} name={'check filled circle'} />} content={__('Enabled')} hideOnScroll basic />)
            : (<Popup trigger={<Icon style={{ color: '#F03E3E' }} name={'times fille  d circle'} />} content={__('Disabled')} hideOnScroll basic />))
          :
          (scopeSettings[item.field][action] && scopeSettings[item.field][action].info ?
            <Popup
              trigger={<ColoredCheckbox
                radio
                radioChecked
                color="#084FFF"
                checked={scope[item.field][action]}
                data-action={`select-${item.field}`}
                data-value={action}
                onClick={() => this.onToggleCheckbox(item.field, action)}
              />}
              content={scopeSettings[item.field][action].info}
            />
            : scopeSettings[item.field][action].info || (!!this._approveMessageContent(item, action, hasMessageApproval)) ?
              <Popup
                trigger={<ColoredCheckbox
                  radio
                  radioChecked
                  color="#084FFF"
                  checked={scope[item.field][action]}
                  data-action={`select-${item.field}`}
                  data-value={action}
                  onClick={() => this.onToggleCheckbox(item.field, action)}
                  disabled={!!this._approveMessageContent(item, action, hasMessageApproval)}
                />}
                position="top center"
                style={styles.popup}
                content={scopeSettings[item.field][action].info || this._approveMessageContent(item, action, hasMessageApproval).info}
              />
              :
              <ColoredCheckbox
                radio
                radioChecked
                color="#084FFF"
                checked={scope[item.field][action]}
                data-action={`select-${item.field}`}
                data-value={action}
                onClick={() => this.onToggleCheckbox(item.field, action)}
              />
          )}
      </div>
    );
  }

  renderMobilePermissions = (disabled) => {
    const { scope } = this.state;
    const { store } = this.props;
    const features = store.currentOrganization && store.currentOrganization.features;
    const confCharge = store.currentOrganization && store.currentOrganization.confCharge;

    let options = [
      { title: features && features.messageManager && features.messageManager === 'BASIC' ? __('Organization moments') : __('Organization messages and moments'), field: 'messages', icon: 'envelope' },
      { title: __('Reports'), field: 'reports', icon: 'list layout' },
      { title: __('Entities, Channels and Groups'), field: 'entities', icon: 'users' },
      { title: __('Organization dashboard'), field: 'dashboard', icon: 'tachometer alt' },
      { title: __('Integrations'), field: 'integration', icon: 'puzzle piece' },
      { title: __('Payments: Balance and Charges Dashboards'), field: 'payments', icon: 'donate' },
      { title: __('Payments: Banks and Withdrawals'), field: 'accounts', icon: 'university' },
      { title: __('Forms'), field: 'forms', icon: 'poll h' },
      { title: __('Organization configurations'), field: 'organization', icon: 'cog' },
      { title: __('Accesses'), field: 'links', icon: 'box open full' }
    ];

    if (features && features.messageManager && features.messageManager === 'BASIC' && !(features && features.moments)) options = options.filter(o => o.field !== 'messages');
    if (!(features && features.reports)) options = options.filter(o => o.field !== 'reports');
    if (!(features && features.api)) options = options.filter(o => o.field !== 'integration');
    if (!((features && features.charges) && confCharge)) options = options.filter(o => o.field !== 'payments' && o.field !== 'accounts');
    if (!(features && features.links)) options = options.filter(o => o.field !== 'links');
    if (!(features && features.forms)) options = options.filter(o => o.field !== 'forms');

    return (
      <div style={{ paddingLeft: '10px', paddingBottom: '110px' }}>
        <div>
          {options.map(item =>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '40%', display: 'flex', alignItems: 'center', padding: '8px' }}>
                <Icon name={item.icon} style={{ fontSize: '20px', marginRight: '9px', fontWeight: 300, color: 'rgba(0, 0, 0, 0.6)' }} />
                <span>{item.title}</span>
              </div>
              {['read', ['createUpdate', 'update'], 'delete'].map(__key => this.renderMobileTableItem(item, __key, disabled, scope))}
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { store } = this.props;
    const { scope } = this.state;

    const mobileHeaders = [
      { title: __('Permissions'), style: { width: '40%', textAlign: 'left', fontSize: '14px', fontWeight: 'bold' } },
      { title: __('Visualizing'), style: { width: '20%', textAlign: 'center', fontSize: '14px', } },
      { title: __('Edit'), style: { width: '20%', textAlign: 'center', fontSize: '14px', paddingLeft: '6px' } },
      { title: __('Deleting'), style: { width: '20%', textAlign: 'center', fontSize: '14px', paddingLeft: '15px' } }
    ];
    const actionStyle = this.isMobile() ? {} : {
      paddingLeft: '14px',
      paddingRight: '14px'
    };

    return (
      <BetaModal
        id="SelectPermissions"
        header={__('Manage Permissions')}
        closeOnRootNodeClick={false}
        size={'small'}
        fullScreen={this.isMobile()}
        subHeader={this.isMobile() &&
          <div style={{ display: 'flex', padding: '10px 20px 10px 30px' }}>
            {mobileHeaders.map(header => <div style={header.style}>{header.title}</div>)}
          </div>
        }
        actions={
          <div style={{ ...styles.footer, width: '100%', ...actionStyle }}>
            <Button
              transparent
              round
              style={{ marginRight: '12px', paddingRight: '15px' }}
              text={__('Cancel')}
              onClick={() => { this.props.store.appends.pop(); }}
            />
            <Button
              round
              text={__('Save')}
              style={styles.button}
              icon={{ name: 'check' }}
              onClick={() => { this.handleClick(); }}
            />
          </div>
        }
        fixHeader={this.isMobile()}
        fixActions={this.isMobile()}
        onClose={() => this.props.store.appends.pop()}
        content={
          <div>
            {this.isMobile() ? this.renderMobilePermissions(store.currentEntity.type !== 'ADMIN')
              : this.renderPermissions(store.currentEntity.type !== 'ADMIN')}
          </div>
        }
      />
    );
  }
}

@inject('store')
export default class SelectPermissions extends SelectPermissionsModal {
  constructor(props) {
    super(props);
  }
} 
