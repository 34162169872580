import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { __ } from '../../i18n';
import SelectGroupModal from '../ui/SelectGroupModal';
import Button from './Button';
import Input from './Input';
import Tag from './Tag';
import Responsive from '../Responsive';

import { inject, observer } from 'mobx-react';

const styles = {
  modal: {
    borderRadius: '12px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.429rem',
    color: '#000000',
    fontWeight: 700
  },
  icon: {
    fontSize: '1.5rem',
    cursor: 'pointer',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  primaryButton: {
    backgroundColor: '#084FFF',
    color: '#FFF',
    fontWeight: 'normal',
    marginLeft: '0.75rem'
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    fontWeight: 'normal',
    fontSize: '1rem'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '3rem'
  },
  block: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    height: '2.5rem',
    margin: '2rem 0',
    display: 'flex',
    alignItems: 'center'
  }
};

@inject('store')
@observer
export default class SelectGroup extends Responsive {
  constructor(props) {
    super(props);

    this.types = [
      { name: 'custom', label: __('Personalize') },
      { name: 'public', label: __('Visible for all') }
    ];

    this.state = {
      loading: true,
      selectedOption: '',
      selectedItems: props.selectedItems || [],
      searchText: '',
      groupsPopupOpen: false,
      keepGroupsPopupOpen: false
    };
  }

  onDeleteGroup = (id) => {
    const selectedItems = [...this.props.selectedItems];
    const foundSelected = selectedItems.findIndex(item => item.id === id);

    selectedItems.splice(foundSelected, 1);

    this.props.onDelete(selectedItems);
  }

  renderGroupsTags = (items) => {
    const { alternativeTags } = this.props;
    const [firstGroup, ...otherGroups] = items;

    if (!alternativeTags) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0', flexWrap: 'wrap' }}>
          {items.map((item, i) => <Tag style={{ margin: '0.4rem' }} tooltipOnOverflow name={item.name} className="gray big" onDelete={() => { this.onDeleteGroup(item.id); }} key={i} />)}
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem 0', flexWrap: 'wrap' }}>
        {<Tag
          style={{
            margin: '0',
            background: 'none',
            border: '1px solid rgba(0, 0, 0, 0.16)',
            borderRadius: 200
          }}
          tooltipOnOverflow
          name={firstGroup.name}
          className="gray big"
          onDelete={() => { this.onDeleteGroup(firstGroup.id); }}
        />}
        {otherGroups.length > 0 &&
          <Popup
            key={2}
            hideOnScroll
            mouseLeaveDelay={1000}
            open={this.state.groupsPopupOpen}
            onOpen={() => this.setState({ groupsPopupOpen: true })}
            onClose={() => {
              if (this.state.groupsPopupOpen && !this.state.keepGroupsPopupOpen) this.setState({ groupsPopupOpen: false });
            }}
            trigger={
              <div
                style={{
                  marginLeft: 8,
                  height: 40,
                  width: 40,
                  border: '1px solid rgba(0, 0, 0, 0.16)',
                  borderRadius: 200,
                  padding: '4px 8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                +{otherGroups.length}
              </div>
            }

            content={
              <div
                onMouseEnter={() => this.setState({ keepGroupsPopupOpen: true })}
                onMouseLeave={() => this.setState({ groupsPopupOpen: false, keepGroupsPopupOpen: false })}
                className="scroll-popup"
              >
                {otherGroups.map((item, i) =>
                  <Tag
                    key={i}
                    style={{
                      height: 40,
                      border: '1px solid rgba(0, 0, 0, 0.16)',
                      borderRadius: 200,
                      padding: '16px 8px 16px 16px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    tooltipOnOverflow
                    name={item.name}
                    className="gray big popupTag"
                    onDelete={() => { this.onDeleteGroup(item.id); }}
                  />)}
              </div>
            }
          />}
      </div>
    );
  };

  renderPublicGroups = () => (
    <div style={styles.block}>
      <span style={{ marginLeft: '24px', color: 'rgba(0, 0, 0, 0.6)' }}>
        {__('The channel is visible for all groups')}
      </span>
    </div>
  )

  renderNoGroups = () => (
    <div style={styles.block}>
      <span style={{ marginLeft: '24px', color: 'rgba(0, 0, 0, 0.6)' }}>
        {__('No group selected')}
      </span>
    </div>
  );

  render() {
    const { onSubmit, onChange, selectedItems, visibility, visibilityFields, error, disabled,
      withSelectAll, showSelectedTotalCount } = this.props;
    const isPublic = visibility === 'public';

    return (
      <div id="SelectGroup">
        {
          visibilityFields &&
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {this.types.map(option => (
              <Input
                disabled={disabled}
                key={`input-radio-${option.name}`}
                type="radio"
                id={`input-radio-${option.name}`}
                name={option.name}
                value={option.label}
                selected={visibility === option.name}
                onChange={e => onChange(e.target.name)}
              />
            ))}
          </div>
        }
        {
          isPublic &&
          this.renderPublicGroups()
        }
        {!isPublic &&
          <div>
            {selectedItems.length > 0 ? this.renderGroupsTags(selectedItems) : this.renderNoGroups()}
            <Button
              transparent
              round
              text={__('Add group')}
              icon={{ name: 'plus', style: error ? { color: '#BF2600' } : null }}
              style={error ? { border: '1px solid #BF2600', color: '#BF2600' } : null}
              // eslint-disable-next-line react/jsx-curly-spacing
              onClick={() => {
                this.props.store.appends.push(
                  <SelectGroupModal
                    organizationId={this.props.organizationId}
                    selectedGroupsObjects={selectedItems}
                    onSubmit={onSubmit}
                    withSelectAll={withSelectAll}
                    showSelectedTotalCount={showSelectedTotalCount}
                  />
                );
              }}
            />
          </div>}
        {!isPublic && error && (
          <div className="error-warning" style={error && error.style && error.style}>
            <Icon className="circle exclamation" />
            <span>{typeof error !== 'string' && error.text ? error.text : error}</span>
          </div>
        )}
      </div>
    );
  }
}
