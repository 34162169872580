import React from 'react';
import { inject, observer } from 'mobx-react';
import Dropzone from 'react-dropzone';
import {
  Icon
} from 'semantic-ui-react';
import Cropper from 'react-cropper';

import 'cropperjs/dist/cropper.css';

import Responsive from '../Responsive';
import BetaModal from '../ui/Modal';
import Button from '../ui/Button';

import '../../assets/css/ui/ImageEditor.module.scss';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import Avatar from '../Avatar';

const ATTACHMENT_SIZE_MB = 25;
const BYTES_IN_ONE_MB = 1e+6;

const DeleteModal = props => (
  <BetaModal {...props} />
);

@inject('store') @observer
export default class ImageEditor extends Responsive {
  state = {
    image: null,
    deleteDialog: this.props.type === 'delete',
    loading: false
  }

  onSubmit = () => {
    this.setState({ loading: true });

    const dataUrl = this.cropper.getCroppedCanvas().toDataURL();

    const block = dataUrl.split(';');
    const contentType = block[0].split(':')[1];
    const realData = block[1].split(',')[1];
    const blob = utils.b64toBlob(realData, contentType);

    this.props.onSubmit(blob);

    this.setState({ loading: false });
    this.props.store.appends.pop();
  }

  onDelete = () => {
    this.props.onDelete();
    this.props.store.appends.pop();
  }

  onDrop = (accepted, rejected) => {
    const { store } = this.props;
    if (rejected.length > 0) {
      if (rejected[0].size > ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB) {
        store.snackbar = { active: true, message: __('File too big, maximum size is %s MB.', ATTACHMENT_SIZE_MB), success: false };
      } else {
        store.snackbar = { active: true, message: __('File format not accepted, please provide an image.'), success: false };
      }
    }

    this.setState({ image: accepted[0] });
  }

  renderImage = () => {
    const { image } = this.state;
    const { circular } = this.props;
    const height = this.props.height || 400;
    const width = this.props.width || 400;
    const params = {};

    if (circular) {
      params.className = 'clsp-circular';
    }

    return (<Cropper
      ref={c => this.cropper = c}
      src={image && image.preview}
      aspectRatio={width / height}
      guides={false}
      style={{ maxHeight: 400 }}
      {...params}
    />);
  }

  render() {
    const { store, header, picture, type, canEdit, closeOnBack } = this.props;
    const iconStyle = {
      display: 'flex',
      width: '32px',
      height: '32px',
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: 'rgba(0, 0, 0, 0.24)',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    };
    const dropzoneStyle = {
      width: '0px',
      height: '0px',
      borderWidth: 'unset',
      borderColor: 'unset',
      borderStyle: 'unset',
      borderRadius: 'unset',
    };

    return (
      <div>
        <BetaModal
          id={this.props.id}
          onClose={() => (this.props.onCancel ? this.props.onCancel() : this.props.store.appends.pop())}
          closeOnBack={closeOnBack}
          header={header}
          // size="small"
          content={
            <div>
              {
                !this.state.image ?
                  picture ?
                    canEdit ?
                      (<div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        <div style={{ height: '105px', width: this.isMobile() ? '100%' : '135px', zIndex: 2, position: 'absolute' }}>
                          <Dropzone
                            ref={(node) => { this.dropzone = node; }}
                            onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                            multiple={false}
                            className={this.isMobile() ? 'dropzone-mobile' : 'dropzone'}
                            accept="image/jpeg,image/png"
                            maxSize={ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB}
                            style={dropzoneStyle}
                          >
                            <Icon
                              className="pencil"
                              style={{ ...iconStyle, top: '108px', left: '108px', margin: 0, color: '#FFFFFF', fontSize: 16 }}
                            />
                          </Dropzone>
                        </div>
                        <Avatar
                          src={picture instanceof Blob ? URL.createObjectURL(picture) : picture && picture.uri}
                          alt={''}
                          circular
                        />
                      </div>)
                      :
                      (<Avatar
                        src={picture instanceof Blob ? URL.createObjectURL(picture) : picture && picture.uri}
                        alt={''}
                        circular
                      />)
                    :
                    (<Dropzone
                      ref={(node) => { this.dropzone = node; }}
                      onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                      multiple={false}
                      accept="image/jpeg,image/png"
                      maxSize={ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB}
                      className="ui button fluid large dropzone"
                      style={{ borderStyle: 'dashed', borderWidth: 1 }}
                    >
                      <Icon name="arrow circle down" />
                      <span>{__('Drop file here or click to browse')}</span>
                    </Dropzone>)
                  :
                  [
                    <p>{__('Drag to reposition')}</p>,
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                      {this.renderImage()}
                    </div>
                  ]
              }
            </div>}
          actions={[
            picture && !this.state.image &&
            <Button
              data-action="delete"
              color="red"
              transparent
              style={{ marginLeft: 0, marginRight: 'auto' }}
              round
              text={__('Remove Picture')}
              onClick={() => this.setState({ deleteDialog: true })}
            />,
            !this.isMobile() &&
            <Button
              data-action="cancel"
              transparent
              round
              floated="left"
              text={__('Cancel')}
              onClick={() => (this.props.onCancel ? this.props.onCancel() : this.props.store.appends.pop())}
              disabled={this.props.loading || this.state.loading}
            />,
            <Button
              data-action="submit"
              primary
              round
              icon={{ name: 'check' }}
              text={type === 'add' ? __('Add') : __('Save')}
              onClick={this.onSubmit}
              loading={this.props.loading || this.state.loading}
              disabled={this.props.loading || !this.state.image || this.state.loading}
            />
          ]}
        />
        <DeleteModal
          id={'DeletePicture'}
          className="beta-modal"
          open={this.state.deleteDialog}
          onClose={() => this.props.store.appends.pop()}
          header={__('Delete Image')}
          size="small"
          content={<p style={{ fontSize: '16px' }}>{__('Are you sure you want to delete?')}</p>}
          actions={[
            <Button
              data-action="cancel"
              transparent
              round
              text={__('Cancel')}
              onClick={() => (type === 'delete' ? store.appends.pop() : this.setState({ deleteDialog: false }))}
              loading={this.state.loading}
              disabled={this.props.loading || this.state.loading}
            />,
            <Button
              data-action="delete"
              red
              round
              icon={{ name: 'trash outline' }}
              text={__('Delete')}
              onClick={this.onDelete}
              loading={this.props.loading || this.state.loading}
              disabled={this.props.loading || this.state.loading}
            />
          ]}
        />
      </div>
    );
  }
}
