import React from 'react';
import { inject, observer } from 'mobx-react';

import { __ } from '../../i18n';
import SelectPermissionsModal from '../ui/SelectPermissionsModal';
import Button from './Button';
import ApprovalPermissionsModal from '../ApprovalPermissionsModal';

const types = [
  { name: 'custom', label: __('Custom') },
  { name: 'public', label: __('Public') }
];

const styles = {
  modal: {
    borderRadius: '12px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.429rem',
    color: '#000000',
    fontWeight: 700
  },
  icon: {
    fontSize: '1.5rem',
    cursor: 'pointer',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  primaryButton: {
    backgroundColor: '#084FFF',
    color: '#FFF',
    fontWeight: 'normal',
    marginLeft: '0.75rem'
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    fontWeight: 'normal',
    fontSize: '1rem'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '3rem'
  },
  block: {
    boxShadow: '-1px 0px 0px rgba(0, 0, 0, 0.1)',
    height: '2.5rem',
    margin: '2rem 0',
    display: 'flex',
    alignItems: 'center'
  },
  messageApprovalStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 14,
  }
};

const getApproveButtonText = (messageApproval, hasMenuConversations) => {
  if (messageApproval) {
    if (hasMenuConversations)
      return __('Communication approval');

    return __('Message approval');
  }
  return __('Select permissions');
}

@inject('store')
@observer
export default class SelectPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedOption: 'public',
      searchText: '',
      showEntityInformationText: false,
    };
  }

  render() {
    const { onSubmit, scope, policy, openPaywall, messageApproval, entity, isChannel, radioList, listTitle, hasMenuConversations } = this.props;

    return (
      <div>
        <div>
          <Button
            data-testid='communication-approval-button'
            role='button'
            transparent
            round
            paywall={openPaywall}
            text={getApproveButtonText(messageApproval, hasMenuConversations)}
            icon={messageApproval ? null : { name: 'cog' }}
            messageApprovalIcon={messageApproval}
            style={messageApproval && styles.messageApprovalStyle}
            // eslint-disable-next-line react/jsx-curly-spacing
            onClick={() => {
              if (openPaywall) {
                openPaywall();
              } else {
                this.props.store.appends.push(
                  messageApproval ? (
                    <ApprovalPermissionsModal
                      entity={entity}
                      onClose={() => this.props.store.appends.pop()}
                      onCancel={() => this.props.store.appends.pop()}
                      isChannel={isChannel}
                      radioList={radioList}
                      listTitle={listTitle}
                      onSubmit={onSubmit}
                      policy={policy}
                      scope={scope}
                      hasMenuConversations={hasMenuConversations}
                    />
                  ) : (
                    <SelectPermissionsModal onSubmit={onSubmit} scope={scope} />
                  )
                );
              }
            }}
          />
        </div>
      </div>
    );
  }
}
