import React from 'react';

import { inject, observer } from 'mobx-react';

import Button from './Button';
import Avatar from '../Avatar';
import ImageEditor from '../ui/ImageEditor';
import Responsive from '../Responsive';

import { __ } from '../../i18n';

const styles = {
  photoButton: {
    marginRight: '0.75rem',
    marginTop: '10px'
  },
  icon: {
    fontSize: '1.25rem',
    margin: '0'
  },
  avatar: {
    width: '64px',
    height: '64px',
    marginRight: '8px',
    objectFit: 'cover',
  },
  circularButton: {
    width: '48px',
    height: '48px',
    weight: '400px',
    fontSize: '16px',
    marginRight: '8px',
  }
};

@inject('store') @observer
export default class PictureInput extends Responsive {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  openPictureModal = (type = 'add') => {
    const { onSubmit, onDelete, picture } = this.props;

    this.props.store.appends.push(
      <ImageEditor
        id="EntityPicture"
        header={type === 'add' ? __('Add picture') : __('Edit picture')}
        onSubmit={onSubmit}
        onDelete={onDelete}
        type={type}
        picture={picture}
        circular
        closeOnBack
      />
    );
  };

  render() {
    const { icons, picture, fullname, hideEdit } = this.props;

    if (picture && (picture.uri || picture instanceof Blob)) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            size="small"
            src={picture instanceof Blob ? URL.createObjectURL(picture) : picture && picture.uri}
            alt={fullname}
            circular
            style={styles.avatar}
          />
          {
            !hideEdit &&
            <Button
              transparent
              style={styles.circularButton}
              round
              icon={{ name: 'pen', style: { margin: '0' } }}
              onClick={() => this.openPictureModal('edit')}
            />
          }
          {
            !hideEdit &&
            <Button
              transparent
              style={styles.circularButton}
              round
              icon={{ name: 'trash can', style: { margin: '0' } }}
              onClick={() => this.openPictureModal('delete')}
            />
          }
        </div>
      );
    }

    return (
      <div>
        <Button
          transparent
          style={styles.photoButton}
          round
          text={__('Add picture')}
          icon={{ name: 'paperclip' }}
          onClick={() => this.openPictureModal()}
        />
        {!this.isMobile() ?
          (icons ?
            icons.map((icon, key) => (
              <div key={key} style={{ display: 'inline-block' }}>
                <Button
                  style={{
                    ...styles.photoButton,
                    backgroundColor: icon.backgroundColor || '#016EEA',
                    color: icon.color || '#FFFFFF'
                  }}
                  round
                  icon={{
                    name: icon.name,
                    style: styles.icon
                  }}
                  onClick={icon.onClick}
                />
              </div>
            )) : '')
          :
          <div style={{ marginTop: '5px' }}>
              { icons ?
              icons.map((icon, key) => (
                <div key={key} style={{ display: 'inline-block' }}>
                  <Button
                    style={{
                      ...styles.photoButton,
                      backgroundColor: icon.backgroundColor || '#016EEA',
                      color: icon.color || '#FFFFFF'
                    }}
                    round
                    icon={{
                      name: icon.name,
                      style: styles.icon
                    }}
                    onClick={icon.onClick}
                  />
                </div>
              )) : ''}
            </div>
        }
      </div>
    );
  }
}

