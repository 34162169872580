import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import '../../assets/css/ui/Tag.module.scss';

export default class Tag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowing: false
    };
  }

  isEllipsisActive(e) {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  }

  componentDidMount() {
    this.setState({ isOverflowing: this.isEllipsisActive(this.container.firstChild) });
  }

  render() {
    const {
      name, onDelete, style, className, tooltipOnOverflow
    } = this.props;

    return (
      <div className={`global-tag coloured background ${className || ''}`} style={style} ref={ref => this.container = ref}>
        {tooltipOnOverflow && this.state.isOverflowing ? 
          <Popup
            trigger={
              <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{name}</span>
            }
            content={name}
            hoverable
            hideOnScroll
            position='top right'
          />
          :
          <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{name}</span>
        }

        {onDelete && <Icon className="times" style={{ fontWeight: 400, color: className.includes('gray') ? 'rgba(0,0,0,.60)' : null }} onClick={onDelete} />}
      </div>
    );
  }
}
